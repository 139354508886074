import { UserTokens } from '../domain/User'

type UserTokensAPI = {
  access_token: string
  refresh_token: string
  session_token?: string
}

const parse = (tokens: UserTokensAPI): UserTokens => ({
  accessToken: tokens.access_token,
  refreshToken: tokens.refresh_token,
  sessionToken: tokens.session_token ?? ''
})

export default { parse }
