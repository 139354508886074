/* istanbul ignore file */
import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const primary = definePartsStyle({
  control: defineStyle({
    _checked: {
      backgroundColor: 'primary',
      borderColor: 'primary',
      _hover: {
        backgroundColor: 'primary'
      }
    }
  })
})

const black = definePartsStyle({
  control: defineStyle({
    _checked: {
      backgroundColor: 'black',
      borderColor: 'black',
      _hover: {
        backgroundColor: 'black'
      }
    }
  })
})
export const checkboxTheme = defineMultiStyleConfig({
  variants: { primary, black }
})
