import { getFingerprint } from '@middlewares/fingerprint'
import { isTest } from '@utils/env'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useEffect, useState } from 'react'

type UseSignifyd = {
  canRenderComponent: boolean
  fingerprint: Maybe<string>
}

export const useSignifyd = (): UseSignifyd => {
  const [fingerprint, setFingerprint] = useState<Maybe<string>>(null)
  const isTestEnv = isTest()

  useEffect(() => {
    if (!isTestEnv) setFingerprint(getFingerprint())
  }, [isTestEnv])

  return {
    canRenderComponent: !isTestEnv && typeof fingerprint === 'string',
    fingerprint
  }
}
