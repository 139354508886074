type Props = {
  isActive: boolean
}

const applyOptimizely = ({ isActive }: Props) => {
  if (!isActive) return null

  if (
    typeof window.optimizely?.get !== 'function' ||
    typeof window.optimizely?.push !== 'function'
  )
    return null

  const optlyPages = window.optimizely.get('data').pages

  for (const optlyPage in optlyPages) {
    window.optimizely.push({
      type: 'page',
      pageName: optlyPages[optlyPage].apiName,
      isActive: false
    })

    window.optimizely.push({
      type: 'page',
      pageName: optlyPages[optlyPage].apiName
    })
  }
}

export { applyOptimizely }
