import { createContext, useContext } from 'react'
import { Notyf } from 'notyf'
import { isClient } from '@utils/env'

const NotyfContext = createContext(
  isClient()
    ? new Notyf({
        duration: 5000,
        position: { x: 'right', y: 'top' },
        types: [
          {
            type: 'error',
            duration: 7000,
            dismissible: true
          },
          {
            type: 'info',
            duration: 7000,
            background: '#768DE5',
            dismissible: true
          }
        ]
      })
    : null
)

const useNotyfContext = (): Notyf | null => useContext(NotyfContext)

export default NotyfContext
export { useNotyfContext }
