// You don't need to use this module unless you want to unity test your code and need to
// mock global window object on server-side
const getWindow = (): Window | void => {
  if (typeof window !== 'undefined') {
    return window
  }
}

const copy = getWindow()

export { copy as window }
