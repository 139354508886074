import { Text as ChakraText } from '@chakra-ui/react'
import { UIStyles, AsType } from '../types'
import { MouseEventHandler } from 'react'

export type TextProps = {
  sx?: UIStyles
  as?: AsType
  htmlFor?: string
  title?: string
  id?: string
  onMouseEnter?: MouseEventHandler<HTMLParagraphElement>
  onClick?: MouseEventHandler<HTMLParagraphElement>
  casing?: 'capitalize' | 'uppercase' | 'lowercase' | 'none'
  dangerouslySetInnerHTML?: { __html: string }
  lang?: string
  dir?: 'auto' | 'ltr' | 'rtl'
  children?: React.ReactNode
}

const Text = ({ children, as = 'span', ...props }: TextProps) => {
  return (
    <ChakraText as={as} {...props}>
      {children}
    </ChakraText>
  )
}

export default Text
