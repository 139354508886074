import { FormLabel } from '@chakra-ui/react'
import { UIStyles } from '../types'

type LabelProps = {
  sx?: UIStyles
  htmlFor?: string
  children?: React.ReactNode
}

const Label = ({ children, ...props }: LabelProps) => {
  return <FormLabel {...props}>{children}</FormLabel>
}

export default Label
