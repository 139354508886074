import { supportLinks } from './supportLinks'

const SOMETHING_WENT_WRONG = 'Something went wrong, please try again later.'
const SOMETHING_WENT_WRONG_WITH_PAYPAL =
  'Sorry, PayPal is not responding to our request! Please, try again later or try another payment method.'
const SOMETHING_WENT_WRONG_WITH_VENMO =
  'Sorry, Venmo is not responding to our request! Please, try again later or try another payment method.'
const PHONE_NUMBER_MISSING_ON_PAYPAL =
  "Sorry, PayPal couldn't return a phone number for your account, verify if you have one or if it's confirmed."
const SUCCESSFUL_PURCHASE =
  'Successful purchase! You are being redirected to the order confirmation page.'
const EMAIL_ALREADY_TAKEN =
  'This email is already associated with an account. Please login to continue or reset your password.'
const INVALID_CREDENTIALS =
  'Invalid email or password. Your account will be locked after 5 failed attempts. Please use Forgot Password to unlock your account or reset your password.'
const RECAPTCHA_CONFIG_ERROR =
  "Something went wrong. If you feel you've received this message in error, please contact us at botguard@stackcommerce.com"
const CONFIRM_EXCLUSION = {
  title: 'Confirm deletion',
  text: 'Are you sure you want delete this?'
}

const fraudulentOrder = (): string => {
  return `
    There was an error processing your purchase.<br>
    Please <a href="${supportLinks.contact()}" style="color: white;">contact support</a>.<br>
    Error Code: 900.
  `
}

export {
  SOMETHING_WENT_WRONG,
  SOMETHING_WENT_WRONG_WITH_PAYPAL,
  SOMETHING_WENT_WRONG_WITH_VENMO,
  PHONE_NUMBER_MISSING_ON_PAYPAL,
  SUCCESSFUL_PURCHASE,
  EMAIL_ALREADY_TAKEN,
  CONFIRM_EXCLUSION,
  INVALID_CREDENTIALS,
  RECAPTCHA_CONFIG_ERROR,
  fraudulentOrder
}
