/* istanbul ignore file */

import { StyleFunctionProps } from '@chakra-ui/react'

import { determineFontColor } from './utils/colors'

const defaultStyle = (props: StyleFunctionProps) => ({
  bg: 'white',
  color: 'black',
  border: '1px solid',
  borderColor: 'white',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontFamily: 'body',
  lineHeight: 'button',
  borderRadius: '3xl',
  verticalAlign: 'middle',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
  display: 'inline-flex',
  '&:hover': {
    color: determineFontColor({
      backgroundColor: props.theme.colors.white,
      desiredColor: props.theme.colors.primary,
      fallbackColor: props.theme.colors.black
    }),
    borderColor: 'white'
  },
  '&:active': {
    color: determineFontColor({
      backgroundColor: props.theme.colors.white,
      desiredColor: props.theme.colors.primaryHover,
      fallbackColor: props.theme.colors.black
    }),
    borderColor: 'white'
  },
  '&:focus': {
    outline: 'none'
  }
})

const buttons = {
  defaultProps: {
    variant: 'primary'
  },
  variants: {
    default: (props: StyleFunctionProps) => defaultStyle(props),
    primary: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: determineFontColor({
        backgroundColor: props.theme.colors.primary,
        desiredColor: props.theme.colors.black,
        fallbackColor: props.theme.colors.white
      }),
      bg: 'primary',
      borderColor: 'primary',
      '&:hover': {
        bg: 'primaryHover',
        borderColor: 'primaryHover'
      },
      '&:active': {
        bg: 'primaryActive',
        borderColor: 'primaryActive'
      }
    }),
    tertiary: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: 'black',
      bg: 'white',
      fontWeight: 'normal',
      borderColor: 'grays.150',
      '&:hover': {
        color: 'primaryHover',
        borderColor: 'primaryHover'
      },
      '&:active': {
        color: 'primaryActive',
        borderColor: 'primaryActive'
      }
    }),
    light: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: 'black',
      bg: 'white',
      borderColor: 'white',
      '&:hover, &:hover:disabled': {
        color: 'black',
        bg: 'white'
      },
      '&:active': {
        bg: 'white',
        borderColor: 'white'
      }
    }),
    link: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: 'black',
      bg: 'transparent',
      fontWeight: 'body',
      textTransform: 'none',
      letterSpacing: 'none',
      textDecoration: 'underline',
      padding: 0,
      margin: 0,
      border: 0,
      justifyContent: 'normal',
      '&:hover': {
        color: 'black'
      },
      '&:active': {
        color: 'black'
      },
      '&:focus': {
        outline: 'none',
        boxShadow: 'none'
      }
    }),
    capsLink: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      textTransform: 'uppercase',
      letterSpacing: '0.225em',
      color: 'black',
      bg: 'transparent',
      padding: 0,
      border: 0,
      '&:hover': {
        color: 'primaryHover'
      },
      '&:active': {
        color: 'primaryActive'
      },
      '&:focus': {
        outline: 'none',
        boxShadow: 'none'
      }
    }),
    dark: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: 'white',
      bg: 'blacks.500',
      borderColor: 'blacks.500',
      '&:hover, &:hover:disabled': {
        color: 'white',
        bg: 'blacks.400'
      },
      '&:active': {
        bg: 'blacks.400',
        borderColor: 'blacks.400'
      }
    }),
    outline: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: 'black',
      bg: 'transparent',
      borderColor: 'primary',
      '&:hover': {
        color: 'primaryHover',
        borderColor: 'primaryHover'
      },
      '&:active': {
        color: 'primaryActive',
        borderColor: 'primaryActive'
      }
    }),
    outlineDark: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      color: 'black',
      bg: 'transparent',
      borderColor: 'blacks.500',
      '&:hover': {
        color: 'white',
        bg: 'blacks.500',
        borderColor: 'blacks.500'
      },
      '&:active': {
        color: 'white',
        bg: 'blacks.400',
        borderColor: 'blacks.400'
      }
    }),
    close: (props: StyleFunctionProps) => ({
      ...defaultStyle(props),
      border: 0,
      letterSpacing: 'body',
      bg: 'transparent',
      '&:hover': {
        bg: 'transparent'
      },
      '&:active': {
        bg: 'transparent'
      }
    })
  }
} as const

export type Buttons = typeof buttons

export type ButtonsVariants = keyof (typeof buttons)['variants']

export default buttons
