import { Tbody as ChakraTbody } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  sx?: UIStyles
}

const Tbody = ({ children, sx }: Props) => {
  return <ChakraTbody sx={sx}>{children}</ChakraTbody>
}

export { Tbody }
