import { RecaptchaAction } from '../types/actions'
import {
  useCurrentRecaptchaFetchType,
  FetchCurrentTokenType
} from '../types/fetch'

const useCurrentRecaptchaFetch: useCurrentRecaptchaFetchType = ({
  executeRecaptcha
}) => {
  const fetchCurrentToken: FetchCurrentTokenType = async (
    forAction,
    useV2,
    v2Tokens
  ) => {
    if (useV2 && v2Tokens) {
      return v2Tokens[forAction]
    }

    return await _fetchExecuteRecaptcha(forAction)
  }

  const _fetchExecuteRecaptcha = async (action: RecaptchaAction) =>
    await executeRecaptcha(action).then(
      (result: string) => result,
      () => undefined
    )

  return { fetchCurrentToken }
}

export default useCurrentRecaptchaFetch
