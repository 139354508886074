import { Table as ChakraTable } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  sx?: UIStyles
}

const Table = ({ children, sx }: Props) => {
  return <ChakraTable sx={sx}>{children}</ChakraTable>
}

export { Table }
