import GTM from '@lib/gtm/gtm'

type Options = {
  eventCategory?: string
  eventAction?: string
  action?: string
  page?: string
}

const tag = (attrs: object = {}): void => {
  GTM.dataLayer({
    dataLayer: { ...attrs }
  })
}

const tagEvent = (
  event: string,
  dataLayer: Options,
  eventAttrs: object = {}
): void => {
  GTM.dataLayer({
    dataLayer: {
      event,
      ...dataLayer,
      ...eventAttrs
    }
  })
}

const tagSCEvent = (dataLayer: Options, eventAttrs: object = {}): void => {
  GTM.SCDataLayer({
    dataLayer: {
      event: 'SCDataLayer',
      ...dataLayer,
      ...eventAttrs
    }
  })
}

const analytics = {
  page: (page: string): void => {
    tagEvent('PageView', { page })
  },
  track: (category: string, action: string, eventAttrs: object = {}): void => {
    tagEvent(
      'analyticsEvent',
      { eventAction: action, eventCategory: category },
      eventAttrs
    )
  },
  trackSCDataLayer: (action: string, eventAttrs: object = {}): void => {
    tagSCEvent({ action }, eventAttrs)
  },
  dataLayer: (attrs: object = {}): void => {
    tag(attrs)
  }
}

export default analytics
