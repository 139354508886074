import { setCookie, getCookie, removeCookie } from '@utils/cookies'
import { ParsedUrlQuery } from 'querystring'

// Impact.com's tracking links redirect to our website with a irclickid query
// param in the URL. We want the client to save that value in a cookie, so the
// client can retrieve it later and add it to some Segment event
const NAME = 'irclickid'

const setFromQuery = (query: ParsedUrlQuery): void => {
  const value = query[NAME] as string

  if (value) setCookie(NAME, value)
}

type dataForEvent = {
  [NAME]: string | undefined
}

const consumeForEvent = (): dataForEvent => {
  const value = getCookie(NAME)

  if (value) removeCookie(NAME)

  return { [NAME]: value }
}

export default { setFromQuery, consumeForEvent }
