import { getCookie, setCookie } from '@utils/cookies'
import { Maybe } from 'graphql/jsutils/Maybe'

type SetItemListNamesCookieProp = {
  path?: string | Maybe<string>
  itemListName: string
  slug?: string
}

export const setItemListNamesCookie = ({
  path,
  itemListName,
  slug
}: SetItemListNamesCookieProp) => {
  const key =
    slug ||
    (path?.includes('/sales/') ? path.replace('/sales/', '') : 'general')

  const existingItemListNames = (() => {
    try {
      return JSON.parse(getCookie('item_list_names') || '{}')
    } catch {
      console.error('Error parsing item_list_names cookie')
      return {}
    }
  })()

  const updatedItemListNames = { ...existingItemListNames, [key]: itemListName }

  setCookie('item_list_names', JSON.stringify(updatedItemListNames), {
    expires: 1
  })
}

export const getItemListName = (sale_product_slug: string) => {
  try {
    const currentItemListNamesObj = JSON.parse(
      getCookie('item_list_names') || '{}'
    )
    return (
      currentItemListNamesObj[sale_product_slug] ||
      currentItemListNamesObj.general
    )
  } catch {
    console.error('Error parsing item_list_names cookie')
    return undefined
  }
}
