import Script from 'next/script'
import { setCookie } from '@utils/cookies'
import { useSignifyd } from '../hooks/useSignifyd'

const Signifyd: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { canRenderComponent, fingerprint } = useSignifyd()

  return canRenderComponent ? (
    <Script
      defer
      type="text/javascript"
      id="sig-api"
      data-order-session-id={fingerprint}
      data-testid="signifydScript"
      src="https://cdn-scripts.signifyd.com/api/script-tag.js"
      onLoad={() => setCookie('signifyd_script_loaded', 'true')}
      onError={() => setCookie('signifyd_script_loaded', 'false')}
    />
  ) : null
}

export default Signifyd
