import { isClient } from '@utils/env'
import Cookies from 'js-cookie'
import { pathOr } from 'ramda'

type Metadata = { [key: string]: string }

const getKey = (fullCookie: string): string => {
  const regex = /(.*?)=/

  return pathOr('', [1], regex.exec(fullCookie))
}

const getValue = (fullCookie: string): string => {
  const regex = /(=)(.*)/

  return pathOr('', [2], regex.exec(fullCookie))
}

export const parseCookies = (cookieString?: string): Record<string, string> => {
  if (!cookieString) return {}

  return cookieString
    .split('; ')
    .filter((item) => !!item)
    .reduce((cookies: Metadata, cookie): {} => {
      const key = getKey(cookie)

      if (!key) return cookies

      cookies[key] = getValue(cookie)

      return cookies
    }, {})
}

export const getCookie = (
  name: string,
  cookieString?: string
): string | undefined =>
  isClient() ? Cookies.get(name) : parseCookies(cookieString)[name]

export const setCookie = (
  name: string,
  value: string,
  options?: object
): string | undefined =>
  isClient() ? Cookies.set(name, value, options) : undefined

export const removeCookie = (name: string): void =>
  isClient() ? Cookies.remove(name) : undefined
