import Script from 'next/script'

type Props = {
  active?: boolean
  optimizelySnippet?: string
}

const Optimizely: React.FC<React.PropsWithChildren<Props>> = ({
  active,
  optimizelySnippet
}) => {
  if (!active) return null
  if (!optimizelySnippet) return null

  return <Script src={optimizelySnippet} strategy="afterInteractive" />
}

export default Optimizely
