import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { ReCaptchaV2Context } from '@concepts/ReCaptcha/store/contextV2'

import useCurrentRecaptchaFetch from '../hooks/useCurrentRecaptchaFetch'

import {
  CurrentTokenResponse,
  IGoogleReCaptchaConsumerProps
} from '../types/recaptcha'
import { RecaptchaAction } from '../types/actions'

const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [v2Tokens, setTokens] = useState({} as Record<string, string | null>)
  const { executeRecaptcha } =
    useGoogleReCaptcha() as IGoogleReCaptchaConsumerProps

  const { fetchCurrentToken } = useCurrentRecaptchaFetch({
    executeRecaptcha
  })

  const updateV2Token = (token: string | null, forAction: string): void => {
    setTokens({ ...v2Tokens, [forAction]: token })
  }

  const currentToken = async (
    forAction: RecaptchaAction
  ): Promise<CurrentTokenResponse> => {
    const token = await fetchCurrentToken(forAction, true, v2Tokens)

    if (!token) return Promise.reject(new Error('Solve the challenge first'))

    return {
      'g-recaptcha-response': token,
      resolver: 'v2',
      for_action: forAction
    }
  }

  return (
    <ReCaptchaV2Context.Provider
      value={{ currentToken, updateV2Token, v2Tokens }}
    >
      {children}
    </ReCaptchaV2Context.Provider>
  )
}

export default Provider
