import { createContext, useContext } from 'react'
import { WindowDimensionsContextType } from './types'
import { getWindowDimensions } from './utils/getWindowDimensions'

export const WindowDimensionsContext =
  createContext<WindowDimensionsContextType>({
    windowDimensions: getWindowDimensions()
  })

export const useWindowDimensionsContext = (): WindowDimensionsContextType => {
  const context = useContext(WindowDimensionsContext)

  if (!context) {
    throw new Error(
      'useWindowDimensionsContext must be used within an WindowDimensionsProvider and run on client only.'
    )
  }

  return context
}
