import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr } from 'ramda'
import { FastCheckoutAuthPayload } from 'src/generated/graphql'

export const FAST_CHECKOUT_AUTH_QUERY = gql`
  mutation FastCheckoutAuth($input: FastCheckoutAuthInput!) {
    fastCheckoutAuth(input: $input) {
      accessToken
      refreshToken
      sessionToken
      locked
      errors {
        message
        path
      }
    }
  }
`

const fastCheckoutAuth = async (
  email: string,
  code: string,
  apolloClient: TApolloClient
): Promise<FastCheckoutAuthPayload> => {
  return apolloClient
    .mutate({
      mutation: FAST_CHECKOUT_AUTH_QUERY,
      variables: { input: { email, code } }
    })
    .then(pathOr({}, ['data', 'fastCheckoutAuth']))
}

export default fastCheckoutAuth
