import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { UIThemeProps } from '../Theme/theme'

type Props = {
  children: React.ReactNode
  themeToExtend: UIThemeProps
}
const Provider = ({ children, themeToExtend }: Props) => {
  const extendedTheme = extendTheme(themeToExtend)
  return <ChakraProvider theme={extendedTheme}>{children}</ChakraProvider>
}

export default Provider
