import { Heading as ChakraHeading } from '@chakra-ui/react'
import { MouseEventHandler } from 'react'
import { AsType, UIStyles } from '../types'

type HeadingProps = {
  sx?: UIStyles
  as?: AsType
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
  children?: React.ReactNode
}

const Heading = ({ children, ...props }: HeadingProps) => {
  return <ChakraHeading {...props}>{children}</ChakraHeading>
}

export default Heading
