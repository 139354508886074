import { Th as ChakraTh } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  sx?: UIStyles
}

const Th = ({ children, sx }: Props) => {
  return <ChakraTh sx={sx}>{children}</ChakraTh>
}

export { Th }
