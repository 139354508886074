import { Collapse as ChakraCollapse } from '@chakra-ui/react'

type Props = {
  in: boolean
  children: React.ReactNode
}

const Collapse = ({ children, in: open }: Props) => (
  <ChakraCollapse in={open}>{children}</ChakraCollapse>
)

export default Collapse
