import { API_URL } from '@config/env'
import axios, { AxiosInstance } from 'axios'
import { loggedOut, request, response } from './interceptors'

type AxiosSetupOptions = {
  initialHeaders?: Record<string, string>
}

export const create = (options: AxiosSetupOptions): AxiosInstance => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json',
      ...options.initialHeaders
    }
  })

  instance.interceptors.request.use(request)
  instance.interceptors.response.use(response)
  /* istanbul ignore next */
  instance.interceptors.response.use((it) => it, loggedOut)

  return instance
}
