import fonts from './fonts'
import colors from './colors'

export type Styles = Record<string, object>

const styles: Styles = {
  body: {
    fontFamily: fonts.fonts.body,
    fontWeight: fonts.fontWeights.body,
    lineHeight: fonts.lineHeights.body,
    letterSpacing: fonts.letterSpacings.body,
    textRendering: 'optimizeSpeed',
    color: colors.text,
    background: colors.background,
    margin: 0,
    padding: 0
  },

  '[hidden]': {
    visibility: 'hidden'
  },
  '.grecaptcha-badge': {
    visibility: 'hidden'
  },
  input: {
    fontFamily: fonts.fonts.body,
    fontWeight: fonts.fontWeights.body,
    lineHeight: fonts.lineHeights.body
  },
  'header ul, footer ul': {
    margin: 0,
    padding: 0
  },
  'header li, footer li': {
    listStyle: 'none'
  }
}

export default styles
