import { readableColor, darken } from 'polished'
import { mergeDeepRight, pathOr, reject } from 'ramda'
import {
  Publisher,
  NavBar,
  NavHelloBar,
  ProductFlags,
  Header
} from 'src/generated/graphql'
import { Theme } from '../theme'
import { isInvalid } from '@utils/logic'

export const publisherTheme = (theme: Theme, publisher: Publisher): Theme => {
  const navBar = pathOr({}, ['layout', 'navBar'], publisher) as NavBar
  const header = pathOr({}, ['layout', 'header'], publisher) as Header
  const navHelloBar = pathOr(
    {},
    ['layout', 'navHelloBar'],
    publisher
  ) as NavHelloBar
  const productFlags = pathOr(
    {},
    ['layout', 'productFlags'],
    publisher
  ) as ProductFlags
  const primaryColor = pathOr('#4B65D5', ['layout', 'primaryColor'], publisher)

  const colors = reject(isInvalid, {
    primary: primaryColor,
    headerBackground: header.bgColor || '#F4F5F4',
    headerSearchBarColor: header.searchBarColor || '#000000',
    signInHoverColor: header.signInHoverColor || '#222222',
    singInHoverText: header.signInHoverText || '#000000',
    signInHoverOutline: header.signInHoverOutline || '#000000',
    navBarText: navBar.textColor || '#000',
    navBarBg: navBar.bgColor || '#FFF',
    navBarBorder: navBar.borderColor || '#EDEDED',
    menuSecondaryColor: navBar.bgColor
      ? readableColor(String(navBar.bgColor), '#777777', '#8F8F8F', false)
      : '',
    navHelloBarText: navHelloBar.textColor,
    navHelloBarBg: navHelloBar.bgColor,
    navButtonTextDesktopColor: navBar.buttonTextDesktopColor,
    navButtonTextMobileColor: navBar.buttonTextMobileColor,
    navButtonBackgroundMobileColor: navBar.buttonBackgroundMobileColor,
    primaryHover: darken(0.1, primaryColor),
    primaryActive: darken(0.15, primaryColor),
    priceDropTextColor: productFlags.priceDropTextColor || '#FFF',
    priceDropBackgroundColor:
      productFlags.priceDropBackgroundColor || primaryColor,
    newDealTextColor: productFlags.newDealTextColor || '#FFF',
    newDealBackgroundColor: productFlags.newDealBackgroundColor || primaryColor,
    freeShippingTextColor: productFlags.freeShippingTextColor || '#FFF',
    freeShippingBackgroundColor:
      productFlags.freeShippingBackgroundColor || primaryColor
  })

  return mergeDeepRight(theme, { colors }) as Theme
}
