const colors = {
  primary: '#4B65D5',
  primaries: {
    100: '#ECF2FF',
    150: '#C8C9F3',
    200: '#B9C9FA',
    500: '#364CB7',
    600: '#253799',
    700: '#17247B'
  },
  grays: {
    40: '#F9F9F9',
    41: '#F9FAFD',
    50: '#EDEDED',
    60: '#ECF2FFB3',
    65: '#E5E5E5',
    100: '#DBDBDB',
    140: '#C4C4C4',
    150: '#C2C2C2',
    250: '#A8A8A8',
    400: '#8F8F8F',
    600: '#757575',
    650: '#666666',
    700: '#5C5C5C',
    750: '#424242',
    900: '#292929',
    910: '#212121'
  },
  reds: {
    0: '#FFF7F7',
    500: '#EB5757',
    550: '#E63F2E',
    600: '#D43434'
  },
  greens: {
    0: '#EDF6F6',
    50: '#D3F5E2',
    600: '#37B18B',
    700: '#24CE6E'
  },
  blacks: {
    400: '#333333',
    500: '#222222',
    600: '#141414',
    850: '#00000030'
  },
  links: {
    500: '#4B65D5',
    510: '#454AD3',
    700: '#253799'
  },
  success: '#28A745',
  muted: '#CCCCCC',
  danger: '#D53434',
  danger2Alpha: '#EB57570D',
  dangerLight: '#FDF5F5',
  text: '#1C1C1C',
  overlay: 'rgba(0, 0, 0, 0.3)',
  background: '#FFFFFF',
  highlight: '#FFBC57',
  notification: '#F5F8FF',
  activeLightGreen: '#DCEFEB',
  activeGreen: '#1B936E',
  trialLightBlue: '#B9C9FA',
  trialBlue: '#253799',
  info: '#B9C9FA',
  primaryHover: '#253799',
  primaryActive: '#17247B',
  facebookBlue: '#39599A',
  footer: '#090909'
} as const

export type Colors = typeof colors

export default colors
