import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { UIStyles } from '../types'

type Props = {
  sx?: UIStyles
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  id?: string
  name?: string
  isChecked?: boolean
  children?: React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  variant?: string
  value?: string
}

const Checkbox = ({ children, ...props }: Props) => {
  return <ChakraCheckbox {...props}>{children}</ChakraCheckbox>
}

export default Checkbox
