import { Icon as ChakraIcon } from '@chakra-ui/react'
import { AsType, UIStyles } from '../types'

type IconProps = {
  sx?: UIStyles
  as?: AsType | string
}

const Icon = ({ as, ...props }: IconProps) => {
  return <ChakraIcon as={as as AsType} {...props} />
}

export default Icon
