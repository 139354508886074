import { createContext, useContext } from 'react'
import { pathOr } from 'ramda'
import type { Publisher, NavBar } from 'src/generated/graphql'

export const PublisherContext = createContext<Partial<Publisher>>({})

export const usePublisherContext = (): Partial<Publisher> =>
  useContext(PublisherContext)

export const useNavBarContext = (): Partial<NavBar> =>
  pathOr({}, ['layout', 'navBar'], usePublisherContext())
