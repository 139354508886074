import { init as SentryInitialize, setUser, setContext } from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import { APP_ENV, PUBLIC_SENTRY_DSN } from '@config/env'
import { User } from '../concepts/Auth/types/User'

export const setTrackingContext = (user?: User, hostname?: string): void => {
  setUser({ id: user?.id?.toString() || 'GUEST' })
  setContext('publisher', {
    hostname
  })
}

export const init = (): void => {
  const isEnabled = APP_ENV !== 'test' && APP_ENV !== 'development'

  SentryInitialize({
    enabled: isEnabled,
    dsn: PUBLIC_SENTRY_DSN,
    environment: APP_ENV,
    integrations: [new BrowserTracing()],
    // Known GTM race-condition error
    ignoreErrors: [
      /b.getAll/,
      /Network error.*/,
      /ga is not defined/,
      /Non-Error promise rejection captured with value/,
      /null is not an object/,
      /Can't find variable: ga/,
      /Load failed/,
      /Failed to fetch/
    ]
  })
}
