import { GetServerSidePropsContext } from 'next'
import { isClient } from '@utils/env'
import { getCookie } from '@utils/cookies'
import { BaseProps } from 'src/types/page-props'
import { TENANT } from '@config/env'

export const CURRENT_TENANT_KEY = 'current_tenant_hostname'

const getQueryStringValue = (url: string, query: string): string | null => {
  // eslint-disable-next-line no-useless-escape
  const key = query.replace(/[*+?^$.\[\]{}()|\/]/g, '\\$&')
  // eslint-disable-next-line prefer-regex-literals
  const match = url.match(new RegExp('[?&]' + key + '=([^&]+)(&|$)'))

  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

const getHostnameFallback = (cookies: string, url: string): string => {
  const hostname = isClient()
    ? getCookie(CURRENT_TENANT_KEY, cookies)
    : getQueryStringValue(url, CURRENT_TENANT_KEY) ||
      getCookie(CURRENT_TENANT_KEY, cookies)

  return hostname || TENANT
}

export function getCurrentTenant({
  req
}: GetServerSidePropsContext): BaseProps {
  // This guard should stay disabled until we switch publishers DNS to the redesign
  // if (isProduction()) return { hostname: req?.headers.host || '' } as BaseProps

  const url = req?.url || ''
  const cookies = req?.headers.cookie || ''

  return { hostname: getHostnameFallback(cookies, url) } as BaseProps
}
