import { User } from '../types/User'
import { User as GQLUser } from 'src/generated/graphql'
import { isPresent } from '@utils/logic'
import { fullName } from '@utils/strings'
import { pathOr } from 'ramda'

const parse = (
  user: Partial<GQLUser | undefined | null> & { guestToken?: string }
): User => {
  if (!isPresent(user)) return null

  const addresses = pathOr([], ['addresses', 'nodes'], user)

  return {
    id: user.databaseId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: fullName([user.firstName, user.lastName]),
    avatarUrl: user.avatarUrl,
    isGuest: user.isGuest,
    canClaimAccount: user.canClaimAccount,
    guestToken: user.guestToken || null,
    creditsAvailableCents: user.creditsAvailableCents,
    flagged: Boolean(user.flagged),
    info: user.info,
    addresses,
    hasPreviousPurchase: isPresent(user.orders?.nodes),
    favoriteSaleIds: user.favoriteSaleIds || [],
    referrerId: user.referrerId,
    hasSubscriptions: isPresent(user.recurringSales?.nodes),
    hasActiveRecurringSale: user.hasActiveRecurringSale
  } as User
}

export default { parse }
