import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import { GLADLY_CHAT, PUBLISHER } from '@concepts/FeatureFlag/utils/constants'
import { GLADLY_CHAT_APP_ID } from '@config/env'
import { isProduction } from '@utils/env'
import Head from 'next/head'

const GladApp = () => {
  const FeatureFlag = useFeatureFlag()
  const environment = isProduction() ? 'PROD' : 'STAGING'
  const { pathname } = useRouter()

  useEffect(() => {
    if (!pathname.includes('/sales')) return

    const style = document.createElement('style')
    style.innerHTML = `
      @media (max-width: 1024px) {
        div#gladlyChat_container.browser {
          bottom: 60px !important;
        }
      }
    `
    document.head.appendChild(style)
  }, [pathname])

  if (FeatureFlag.isDisabled(GLADLY_CHAT, PUBLISHER)) return null

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(c,n,r,t){if(!c[r]){var i,d,p=[];d="PROD"!==t&&t?
            "STAGING"===t?"https://cdn.gladly.qa/gladly/chat-sdk/widget.js":
            t:"https://cdn.gladly.com/chat-sdk/widget.js",c[r]={init:function(){
            i=arguments;var e={then:function(t){return p.push({type:"t",next:t})
            ,e},catch:function(t){return p.push({type:"c",next:t}),e}};return e}}
            ,c.__onHelpAppHostReady__=function(t){if(delete c.__onHelpAppHostReady__,
            (c[r]=t).loaderCdn=d,i)for(var e=t.init.apply(t,i),n=0;n<p.length;n++)
            {var a=p[n];e="t"===a.type?e.then(a.next):e.catch(a.next)}},function(){
            try{var t=n.getElementsByTagName("script")[0],e=n.createElement("script");
            e.async=!0,e.src=d+"?q="+(new Date).getTime(),t.parentNode.insertBefore(e,t)}catch(t){}}()}}
            (window,document,'Gladly','${environment}')
            `
          }}
        />
      </Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.gladlyConfig = {
            appId: '${GLADLY_CHAT_APP_ID}'
          };
          `
        }}
      />
    </>
  )
}

export default GladApp
