import { TableContainer as ChakraTableContainer } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  sx?: UIStyles
}

const TableContainer = ({ children, sx }: Props) => {
  return <ChakraTableContainer sx={sx}>{children}</ChakraTableContainer>
}

export { TableContainer }
