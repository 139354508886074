import { useMemo } from 'react'
import { TApolloClient } from 'src/types/apollo'
import { isClient } from '@utils/env'
import { dissoc, isEmpty } from 'ramda'
import Client, { OptionsType } from './client'
import { useGlobalContext } from '@config/contexts'
import { parseGuestToken } from '@concepts/Auth/hooks/useGuestToken'
import { ParsedUrlQuery } from 'querystring'
import { Tokens } from '@config/jwt'
import { GetServerSidePropsContext } from 'next'
import { parseCookies } from '@utils/cookies'
import { getCurrentTenant } from '@middlewares/tenancy'
import { useRouter } from 'next/router'
import { ApolloLink } from 'apollo-link'

let apolloClients: Map<string, TApolloClient>

export const clearApolloCache = (): void => {
  apolloClients = new Map()
}

clearApolloCache()

const getOptionsCacheKey = ({ auth, hostname }: OptionsType): string => {
  const enforceAuth = auth || ({} as Tokens)
  const values: (string | undefined)[] = Object.keys(enforceAuth).map(
    (authKey) => enforceAuth[authKey as keyof Tokens]
  )

  return [hostname, ...values].filter((v) => v).join('')
}

export function initializeApollo(
  initialState = {},
  options: OptionsType = {}
): TApolloClient {
  const _cacheKey = getOptionsCacheKey(options)

  const _apolloClient =
    apolloClients.get(_cacheKey) || Client.create(initialState, options)

  if (!isEmpty(initialState)) _apolloClient.cache.restore(initialState)
  if (!isClient()) return _apolloClient
  if (!apolloClients.has(_cacheKey)) apolloClients.set(_cacheKey, _apolloClient)

  return _apolloClient
}

export function _initializeAuthApollo({
  initialState,
  cookies,
  query,
  options,
  callback
}: {
  initialState: Record<string, unknown>
  cookies: Record<string, string>
  query: ParsedUrlQuery
  options: OptionsType
  callback: typeof initializeApollo
}): TApolloClient {
  const { access_token, refresh_token, session_token } = cookies
  const { link, ...rest } = options

  options = {
    auth: {
      accessToken: access_token,
      refreshToken: refresh_token,
      sessionToken: session_token,
      guestToken: parseGuestToken(query).gtoken,
      uidocp: query.uidocp as string
    },
    link,
    ...dissoc('auth', rest)
  }

  return callback(initialState, options)
}

export function initializeAuthApollo(
  initialState = {},
  { req, query }: GetServerSidePropsContext,
  link?: ApolloLink
): TApolloClient {
  const cookies = parseCookies(req?.headers?.cookie)
  const { hostname } = getCurrentTenant({ req } as GetServerSidePropsContext)

  return _initializeAuthApollo({
    initialState,
    cookies,
    query,
    callback: initializeApollo,
    options: {
      hostname,
      link
    }
  })
}

export function useApollo(
  initialState = {},
  options: OptionsType = {}
): TApolloClient {
  const { hostname } = useGlobalContext()

  return useMemo(
    () => initializeApollo(initialState, { hostname, ...options }),
    [hostname, initialState, options]
  )
}

export function useAuthApollo(
  initialState = {},
  options: OptionsType = {}
): TApolloClient {
  const { apolloLink, cookies, hostname } = useGlobalContext()
  const { query } = useRouter()

  options = { link: apolloLink, hostname, ...options }

  return _initializeAuthApollo({
    initialState,
    cookies,
    query,
    options,
    callback: (initialState, options) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMemo(
        () => initializeApollo(initialState, options),
        [initialState, options]
      )
  })
}
