export type Variants = Record<string, object>

const variants: Variants = {
  link: {
    color: 'currentColor',
    textDecoration: 'none',
    fontFamily: 'body'
  }
}

export default variants
