import { window } from '@utils/window'
import { APP_ENV } from '@config/env'

export const isClient = (): boolean => {
  return typeof window !== 'undefined'
}

export const isTest = (): boolean => {
  return APP_ENV === 'test'
}

export const isProduction = (): boolean => {
  return APP_ENV === 'production'
}

export const isDevelopment = (): boolean => {
  return APP_ENV === 'development'
}
