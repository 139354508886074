type Code = string

const PUBLISHER_CODES = {
  stacksocial: 'www',
  tmz: 'TMZ'
}

const PROPRIETARY_CODE_LIST = ['www', 'joyus', 'citizengoods', 'skillwise']

export const isProprietary = (code: string): boolean =>
  PROPRIETARY_CODE_LIST.includes(code)

export const isStackSocial = (code: Code): boolean =>
  code === PUBLISHER_CODES.stacksocial

export const isTMZ = (code: Code): boolean => code === PUBLISHER_CODES.tmz

export default {
  isProprietary,
  isStackSocial,
  isTMZ
}
