import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr } from 'ramda'
import { FastCheckoutSignInPayload } from '../../../generated/graphql'

export const FAST_CHECKOUT_QUERY = gql`
  mutation FastCheckoutSignIn($input: FastCheckoutSignInInput!) {
    fastCheckoutSignIn(input: $input) {
      allowToFastCheckout
      userNameInitials
      lastPhoneNumberDigits
      errors {
        message
        path
      }
    }
  }
`

const fastCheckoutSignIn = async (
  email: string,
  publisherId: number,
  apolloClient: TApolloClient
): Promise<FastCheckoutSignInPayload> => {
  return apolloClient
    .mutate({
      mutation: FAST_CHECKOUT_QUERY,
      variables: { input: { publisherId, email } }
    })
    .then(pathOr({}, ['data', 'fastCheckoutSignIn']))
}

export default fastCheckoutSignIn
