import { useEffect } from 'react'
import Head from 'next/head'
import { Maybe } from 'src/generated/graphql'
import { isProduction } from '@utils/env'
import { FORCE_LOAD_CUSTOM_TRACKING } from '@config/env'
import analytics from '@lib/gtm/analytics'

type Props = {
  fbPixelId?: Maybe<string>
  isSegmentFacebookEnabled: boolean
}

const FBPixel: React.FC<React.PropsWithChildren<Props>> = ({
  fbPixelId,
  isSegmentFacebookEnabled
}) => {
  useEffect(() => {
    if (
      !isSegmentFacebookEnabled &&
      fbPixelId &&
      (isProduction() || FORCE_LOAD_CUSTOM_TRACKING)
    ) {
      analytics.dataLayer({
        event: 'LoadFacebookPixel',
        fbPixelId,
        noScriptSrc: `https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1`
      })
    }
  }, [fbPixelId, isSegmentFacebookEnabled])

  if (isSegmentFacebookEnabled) return null
  if (!fbPixelId || (!isProduction() && !FORCE_LOAD_CUSTOM_TRACKING)) {
    return (
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.fbq = window.fbq || (function(){});
            window._fbq = window._fbq || [];
            `
          }}
        />
      </Head>
    )
  }

  return null
}

export default FBPixel
