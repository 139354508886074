const colors = {
  primary: '#4B65D5',
  primaries: [
    '#ECF2FF',
    '#DCE4FC',
    '#B9C9FA',
    '#94A9F2',
    '#768DE5',
    '#4B65D5',
    '#364CB7',
    '#253799',
    '#17247B',
    '#0E1866',
    '#ECF2FFB3',
    '#C8C9F3'
  ],
  gray: '#8F8F8F',
  grays: [
    '#F9F9F9',
    '#EDEDED',
    '#DBDBDB',
    '#C2C2C2',
    '#A8A8A8',
    '#8F8F8F',
    '#757575',
    '#5C5C5C',
    '#424242',
    '#292929',
    '#212121',
    '#8E8E8E75',
    '#FAFAFA',
    '#E5E5E5',
    '#00000030',
    '#F7F7F7',
    '#C4C4C4',
    '#EEEEEE',
    '#EEEEEEB3',
    '#F9FAFD'
  ],
  reds: ['#FFF7F7', '#D43434', '#E63F2E'],
  greens: ['#EDF6F6', '#37B18B', '#24CE6E', '#5EAE91'],
  text: '#1C1C1C',
  background: '#FFFFFF',
  black: '#000000',
  blacks: ['#222222', '#333333', '#666666', '#1A1A1A', '#141414'],
  white: '#FFFFFF',
  success: '#28A745',
  danger: '#D53434',
  dangerLight: '#FDF5F5',
  danger2: '#EB5757',
  danger2Alpha: '#EB57570D',
  info: '#B9C9FA',
  muted: '#CCCCCC',
  placeholder: '#AAAAAA',
  footer: '#090909',
  facebookBlue: '#39599A',
  link: ['#4B65D5', '#253799', '#454AD3'],
  menuSecondaryColor: '#8F8F8F',
  headerBackground: '',
  headerSearchBarColor: '',
  signInHoverColor: '',
  singInHoverText: '',
  signInHoverOutline: '',
  navBarText: '',
  navBarBg: '',
  navBarBorder: '',
  navHelloBarText: '',
  navHelloBarBg: '',
  priceDropTextColor: '',
  priceDropBackgroundColor: '',
  newDealTextColor: '',
  newDealBackgroundColor: '',
  freeShippingTextColor: '',
  freeShippingBackgroundColor: '',
  highlight: '#FFBC57',
  notification: '#F5F8FF',
  overlay: 'rgba(0, 0, 0, 0.3)',
  activeLightGreen: '#DCEFEB',
  activeGreen: '#1B936E',
  trialLightBlue: '#B9C9FA',
  trialBlue: '#253799',

  // Temporary, until we adapt the admin for more colors
  primaryHover: '#253799',
  primaryActive: '#17247B',
  valid: '#37B18B',
  validLight: '#EDF6F6'
} as const

export type Colors = typeof colors

export default colors
