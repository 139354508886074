import { Select as ChakraSelect } from '@chakra-ui/react'
import { UIStyles } from '../types'
import { forwardRef } from 'react'

export type SelectProps = {
  id?: string
  sx?: UIStyles
  placeholder?: string
  isRequired?: boolean
  value?: number
  options?: string[]
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, ...props }, ref) => {
    return (
      <ChakraSelect ref={ref} {...props}>
        {options?.map((option, index) => (
          <option key={option} value={index}>
            {option}
          </option>
        ))}
      </ChakraSelect>
    )
  }
)

export default Select
