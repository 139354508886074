import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as fbq from '@lib/fbpixel/fbpixel'
import segmentAnalytics from '@lib/segment/analytics'

type Props = {
  isSegmentFacebookEnabled: boolean
}

const FacebookPixelPageView: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isSegmentFacebookEnabled
}) => {
  const router = useRouter()

  const handleRouteChange = (): void => {
    segmentAnalytics.page()

    /* istanbul ignore else */
    if (!isSegmentFacebookEnabled) {
      fbq.pageview()
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)

    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])

  return <>{children}</>
}

export default FacebookPixelPageView
