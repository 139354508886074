import gql from 'graphql-tag'
import { pathOr } from 'ramda'
import { TApolloClient } from 'src/types/apollo'
import { MutationError, RecaptchaParameters } from 'src/generated/graphql'

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      tokenPair {
        accessToken
        refreshToken
      }
      sessionToken
      errors {
        path
        message
      }
    }
  }
`

type MutationResult = {
  success: boolean
  tokenPair: {
    accessToken: string
    refreshToken: string
  }
  sessionToken: string
  errors: Array<MutationError>
}

const resetPassword = async (
  password: string,
  token: string,
  recaptcha: RecaptchaParameters,
  apolloClient: TApolloClient
): Promise<MutationResult> =>
  apolloClient
    .mutate({
      mutation: RESET_PASSWORD_MUTATION,
      variables: { input: { password, token, recaptcha } }
    })
    .then(pathOr({} as MutationResult, ['data', 'resetPassword']))

export default { resetPassword }
