import { RestAPIClient } from '@lib/http'
import { UserFormDTO, UserTokens } from '../domain/User'
import UserTokensDTO from '../dto/UserTokensDTO'

const signIn = async (
  userForm: UserFormDTO,
  APIClient: RestAPIClient
): Promise<UserTokens> => {
  const { data } = await APIClient.post('/users/auth', userForm)

  return UserTokensDTO.parse(data)
}

const signUp = async (
  userForm: UserFormDTO,
  APIClient: RestAPIClient
): Promise<UserTokens> => {
  const { data } = await APIClient.post('/api/shops/users', userForm)

  return UserTokensDTO.parse(data)
}

export default {
  signIn,
  signUp
}
