import { formatMoney } from 'accounting'
import { Maybe } from 'src/types/maybe'

export const formatCents = (
  valueInCents: number,
  options: Object = {}
): string => {
  return formatMoney(valueInCents / 100, options)
}

export const pluralize = (
  qtd: number,
  singular: string,
  plural: string
): string => (qtd === 1 ? singular : plural)

export const truncate = (str: string, num: number): string =>
  str.length > num ? `${str.slice(0, num - 3)}...` : str

export const capitalize = (s: string | unknown): string => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const capitalizeAll = (str: string): string => {
  const regExp = /(?:^|\s|-)\S/g
  return str.replace(regExp, (match) => match.toUpperCase())
}

export const titleize = (str: string): string => {
  const specialCharacterRegExp = /-|_/g
  return capitalizeAll(str.toLowerCase().replace(specialCharacterRegExp, ' '))
}

export const initials = ({
  firstName,
  lastName
}: {
  firstName: string
  lastName: string
}): string => `${firstName.charAt(0)}${lastName.charAt(0)}`

export const fullName = (names: Array<Maybe<string>>): string =>
  names.filter((name) => Boolean(name)).join(' ')

export const toGender = (gender?: string): null | string => {
  if (gender === 'M') return 'Male'
  if (gender === 'F') return 'Female'
  return null
}
