import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { ReCaptchaContext } from '@concepts/ReCaptcha/store/contextV3'

import { RECAPTCHA_CONFIG_ERROR } from '@utils/defaultMessages'
import useCurrentRecaptchaFetch from '../hooks/useCurrentRecaptchaFetch'
import {
  CurrentTokenResponse,
  IGoogleReCaptchaConsumerProps
} from '../types/recaptcha'
import { RecaptchaAction } from '../types/actions'

const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { executeRecaptcha } =
    useGoogleReCaptcha() as IGoogleReCaptchaConsumerProps

  const { fetchCurrentToken } = useCurrentRecaptchaFetch({
    executeRecaptcha
  })

  const currentToken = async (
    forAction: RecaptchaAction
  ): Promise<CurrentTokenResponse> => {
    const token = await fetchCurrentToken(forAction)

    if (!token) return Promise.reject(new Error(RECAPTCHA_CONFIG_ERROR))

    return {
      'g-recaptcha-response': token,
      resolver: 'default',
      for_action: forAction
    }
  }

  return (
    <ReCaptchaContext.Provider value={{ currentToken }}>
      {children}
    </ReCaptchaContext.Provider>
  )
}

export default Provider
