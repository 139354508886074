export const supportLinks = {
  help: () => '/help',
  contact: () => '/contact',
  shipping: () => '/help?g=Shipping%20and%20Tracking',
  digital: () =>
    '/help?g=Digital%2C%20eLearning%2C%20Software%2C%20and%20Subscription%20Purchases%20and%20Order%20Information',
  refunds: () =>
    '/help?a=Refund-and-Cancelation-Policy-Digital-Voucher-or-License-Code---id--B0X7NJrVSHak1qte86iODw',
  warranties: () => '/help?a=Product-Warranties---id--1EQIihiRQqSp8jb9cFx-xA'
}
