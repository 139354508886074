import { User } from '@concepts/Auth/types/User'
import { Publisher, FeatureParameters } from 'src/generated/graphql'

type Feature = {
  name: string
  actor?: string
}

type Props = {
  publisher?: Publisher
  user?: User
  features: Array<Feature>
}

export const getActor = ({
  actor,
  publisher,
  user
}: {
  actor: string | null
  publisher?: Publisher
  user?: User
}): string | null => {
  const actorTypes = {
    publisher: `publisher:${publisher?.databaseId}`,
    user: `user:${user?.id}`
  } as Record<string, string>

  return actor && actorTypes[actor] ? actorTypes[actor] : actor
}

const buildFeatures = ({
  features,
  publisher,
  user
}: Props): Array<FeatureParameters> => {
  return features.map((feature: Feature) => ({
    actor: getActor({
      actor: feature.actor || null,
      publisher,
      user
    }),
    name: feature.name
  }))
}

export default buildFeatures
