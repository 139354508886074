import { useMemo } from 'react'

import { create } from './axios/factory'
import { useGlobalContext } from '@config/contexts'
import { AxiosInstance } from 'axios'

export type RestAPIClient = AxiosInstance

const useRestAPI = () => {
  const { hostname } = useGlobalContext()

  const APIClient: RestAPIClient = useMemo(
    () =>
      create({
        initialHeaders: {
          'X-StackCommerce-Publisher': hostname as string
        }
      }),
    [hostname]
  )

  return APIClient
}

export default useRestAPI
