import { useState, useEffect, useMemo } from 'react'
import { WindowDimensionsContext } from './windowDimensionContext'
import { getWindowDimensions } from './utils/getWindowDimensions'

export const WindowDimensionsProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions)

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const value = useMemo(() => {
    return {
      windowDimensions
    }
  }, [windowDimensions])

  return (
    <WindowDimensionsContext.Provider value={value}>
      {children}
    </WindowDimensionsContext.Provider>
  )
}
