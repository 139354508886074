export enum RecaptchaAction {
  RECAPTCHA_SIGN_IN = 'recaptcha_sign_in',
  RECAPTCHA_SIGN_UP = 'recaptcha_sign_up',
  RECAPTCHA_FORGOT_YOUR_PASSWORD = 'recaptcha_forgot_your_password',
  RECAPTCHA_RESET_PASSWORD = 'recaptcha_reset_password',
  RECAPTCHA_NEWSLETTER_SUBSCRIPTION = 'recaptcha_newsletter_subscription',
  RECAPTCHA_CHECKOUT = 'recaptcha_checkout',
  RECAPTCHA_GIVEAWAY_ENTRY = 'recaptcha_giveaway_entry',
  SSR_CHECKOUT_RECAPTCHA = 'ssr_checkout_recaptcha',
  RECAPTCHA_SETUP_INTENT = 'recaptcha_setup_intent'
}
