import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr } from 'ramda'
import { MutationError } from 'src/generated/graphql'

const SEND_PASSWORD_RECOVERY_INSTRUCTIONS_MUTATION = gql`
  mutation SendPasswordRecoveryInstructions(
    $input: PasswordRecoveryInstructionsInput!
  ) {
    sendPasswordRecoveryInstructions(input: $input) {
      success
    }
  }
`

type MutationResult = {
  success: boolean
  errors: Array<MutationError>
}

const send = async (
  email: string,
  apolloClient: TApolloClient
): Promise<MutationResult> =>
  apolloClient
    .mutate({
      mutation: SEND_PASSWORD_RECOVERY_INSTRUCTIONS_MUTATION,
      variables: { input: { email } }
    })
    .then(
      pathOr({} as MutationResult, ['data', 'sendPasswordRecoveryInstructions'])
    )

export default { send }
