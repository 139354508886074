import { Thead as ChakraThead } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  sx?: UIStyles
}

const Thead = ({ children, sx }: Props) => {
  return <ChakraThead sx={sx}>{children}</ChakraThead>
}

export { Thead }
