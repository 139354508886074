import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { is } from 'ramda'

import { clearTokens, getAuthTokens, updateTokens } from '@config/jwt'
import { isLoginRequest, redirectToLoginPage } from '../utils'

export const request = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { accessToken, refreshToken, sessionToken } = getAuthTokens()

  if (accessToken && refreshToken && is(String, sessionToken)) {
    config.headers!.Authorization = accessToken
    config.headers!['Stack-Refresh-Token'] = refreshToken
    config.headers!['Stack-Session-Token'] = sessionToken
  }

  return config
}

export const response = (response: AxiosResponse): AxiosResponse => {
  const { headers } = response

  updateTokens({
    accessToken: headers['Stack-Token'],
    refreshToken: headers['Stack-Refresh-Token'],
    sessionToken: headers['Stack-Session-Token']
  })

  return response
}

export const loggedOut = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === 401 && !isLoginRequest(error.config?.url)) {
    clearTokens()
    redirectToLoginPage()
  }
  return Promise.reject(error)
}
