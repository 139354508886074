import { Td as ChakraTd } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children?: React.ReactNode
  sx?: UIStyles
}

const Td = ({ children, sx }: Props) => {
  return <ChakraTd sx={sx}>{children}</ChakraTd>
}

export { Td }
