import { getCookie } from '@utils/cookies'
import { filter } from 'ramda'
import { isPresent } from '@utils/logic'
import { GA_SESSION_KEY } from '@config/env'

export const buildHeadersFromCookies = (): object => {
  const cookies = {
    'Ajs-Anonymous-Id': getCookie('ajs_anonymous_id'),
    'Ga-ClientId': getCookie('ga:clientId'),
    'Ga-SessionId': getCookie(GA_SESSION_KEY),
    'Ga-UpsellSaleId': getCookie('ga:upsellSaleId'),
    SubscriberEmail: getCookie('subscriber_email'),
    Fbp: getCookie('_fbp'),
    Fbc: getCookie('_fbc')
  }

  return filter(isPresent, cookies)
}

export const getCartIdFromCookies = (
  cookieString?: string
): string | undefined =>
  getCookie('encrypted_cart_id', cookieString) ||
  getCookie('cart_id', cookieString)
