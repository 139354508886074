import { FocusEventHandler, MouseEventHandler, forwardRef } from 'react'
import { Button as ChakraButton } from '@chakra-ui/react'

import { AsType, UIStyles } from '../types'
import { ButtonsVariants } from '@lib/UIComponents/Theme/buttons'

export type ButtonProps = {
  sx?: UIStyles
  as?: AsType
  title?: string
  children?: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  onBlur?: FocusEventHandler<HTMLButtonElement>
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>
  isDisabled?: boolean
  className?: string
  type?: 'button' | 'submit' | 'reset'
  label?: string
  alt?: string
  variant?: ButtonsVariants | 'unstyled' | 'square'
}

const Button = forwardRef(({ children, ...props }: ButtonProps, ref) => {
  return (
    <ChakraButton ref={ref} {...props}>
      {children}
    </ChakraButton>
  )
})

export default Button
