export const SEARCH_URL = '/search'
export const BRANDS_URL = '/brands'
export const COLLECTION_URL = '/collections'
export const PURCHASES_URL = '/user/purchases'
export const REVIEWS_URL = '/user/reviews'

export const login = (): string => '/sign_in'
export const signUp = (): string => '/sign_up'
export const forgotYourPassword = (): string => '/passwords/new'
export const saleReview = (saleSlug: string): string =>
  `${REVIEWS_URL}/${saleSlug}`
export const receipt = (orderId: number, isGuest: boolean, gtoken?: string) =>
  `/orders/${orderId}/receipt.pdf${isGuest ? `?gtoken=${gtoken}` : ''}`

export default {
  login,
  signUp,
  forgotYourPassword,
  saleReview
}
