import { Tr as ChakraTr } from '@chakra-ui/react'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  sx?: UIStyles
}

const Tr = ({ children, sx }: Props) => {
  return <ChakraTr sx={sx}>{children}</ChakraTr>
}

export { Tr }
