export type ZIndices = Record<string, number>

const zIndices: ZIndices = {
  header: 2,
  overlay: 3,
  topBar: 4,
  menu: 5,
  modal: 6,
  loader: 7
}

export default zIndices
