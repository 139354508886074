import { HTMLAttributeAnchorTarget } from 'react'
import { Link as ChakraLink, forwardRef } from '@chakra-ui/react'
import { UIStyles } from '../types'

export type LinkType = {
  sx?: UIStyles
  children?: React.ReactNode
  href: string
  target?: HTMLAttributeAnchorTarget
  rel?: string
}

const Link = forwardRef(({ children, ...props }: LinkType, ref) => {
  return (
    <ChakraLink ref={ref} {...props}>
      {children}
    </ChakraLink>
  )
})

export default Link
