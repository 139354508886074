export const getDecimalValue = (value: number): number => {
  if (value) return value / 100

  return 0
}

export const toMoney = (n: number): number => parseFloat((n / 100).toFixed(2))

export const toCents = (n: number): number => n * 100

export const toPercentage = (value: number, total: number): number =>
  Math.round((value / total) * 100)
