export type Texts = Record<string, object>

const texts: Texts = {
  default: {
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
    fontSize: [1, 2]
  },
  caps: {
    variant: 'text.default',
    textTransform: 'uppercase',
    letterSpacing: 'caps'
  },
  bold: {
    variant: 'text.default',
    fontWeight: 'bold'
  },
  heading: {
    fontFamily: 'body',
    fontWeight: 'heading',
    lineHeight: 'heading'
  },
  small: {
    fontFamily: 'body',
    fontSize: [1, 1]
  }
}

export default texts
