import { createContext, useContext } from 'react'

type props = {
  currentToken: Function
  v2Tokens: Record<string, string | null>
  updateV2Token: Function
}

export const ReCaptchaV2Context = createContext({} as props)

export const useReCaptchaV2Context = (): props => useContext(ReCaptchaV2Context)
