import buttons from './buttons'
import { checkboxTheme } from './checkbox'
import colors from './colors'
import fontStyles from './fonts'
import zIndices from './z-indices'
import radii from './radii'
import styles from './styles'

const theme = {
  colors,
  radii,
  zIndices,
  components: {
    Button: buttons,
    Checkbox: checkboxTheme
  },
  styles,
  ...fontStyles
}

export type UIThemeProps = typeof theme

export default theme
