import { isPresent } from './logic'
import { pipe, map, filter, join } from 'ramda'

export const isURL = (url: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(url)
    return true
  } catch {
    return false
  }
}

export const toHttpsUrl = (hostname: string): string => `https://${hostname}/`

export type Query = Record<string, string | number | null | undefined>

export const normalizeMenuUrl = (url: string): string => {
  const firstChar = url[0]

  if (isURL(url) || firstChar === '/') return url

  return `/${url}`
}

export const getQueryString = (query: Query): string => {
  if (!isPresent(query)) return ''

  const toQueryString = (val: string): string | null =>
    query[val] ? `${val}=${query[val]}` : null

  const queryString = pipe(
    Object.keys,
    map(toQueryString),
    filter(Boolean),
    join('&')
  )(query)

  return queryString.length ? `?${queryString}` : ''
}

export const getSearchParam = (url: string, param: string) => {
  const regex = new RegExp(`[?&]${param}=([^&]*)`)
  const match = url.match(regex)
  return match ? match[1] : null
}
