import Router from 'next/router'
import { getQueryString, Query } from '@utils/url'

type Redirect = {
  pathname: string
  query?: Query
  external?: boolean
}

const Redirect = ({
  pathname,
  query = {},
  external = true
}: Redirect): void => {
  if (external) {
    const queryString = getQueryString(query)

    window.location.replace(`${pathname}${queryString}`)
  } else {
    Router.push({
      pathname,
      query
    })
  }
}

export default Redirect
