declare global {
  interface Window {
    fbq: Function
  }
}

export const pageview = (): void => {
  window.fbq('track', 'PageView')
}

export const event = (name: string, data = {}): void => {
  window.fbq('track', name, data)
}
