import breakpoints, { Breakpoints as BreakpointType } from './theme/breakpoints'
import colors, { Colors as ColorType } from './theme/colors'
import fonts, { Fonts as FontTypes } from './theme/fonts'
import spaces, { Spaces as SpaceType } from './theme/spaces'
import radii, { Radii as RadiiType } from './theme/radii'
import zIndices, { ZIndices as ZIndiceType } from './theme/z-indices'
import text, { Texts as TextType } from './theme/texts'
import buttons, { Buttons as ButtonType } from './theme/buttons'
import cards, { Cards as CardType } from './theme/cards'
import variants, { Variants as VariantType } from './theme/variants'
import styles, { Styles as StyleType } from './theme/styles'

export type Theme = {
  breakpoints: BreakpointType
  colors: ColorType
  space: SpaceType
  radii: RadiiType
  zIndices: ZIndiceType
  text: TextType
  buttons: ButtonType
  cards: CardType
  variants: VariantType
  styles: StyleType
} & FontTypes

const theme: Theme = {
  breakpoints,
  colors,
  ...fonts,
  space: spaces,
  radii,
  zIndices,
  text,
  buttons,
  cards,
  variants,
  styles
}

export default theme
