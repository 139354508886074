import { pathOr, mergeDeepRight } from 'ramda'
import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module'
import {
  APP_ENV,
  GTM_CONTAINER_AUTH,
  GTM_CONTAINER_ENV,
  GTM_CONTAINER_ID
} from '@config/env'

import { isProduction } from '@utils/env'
import { Affiliate, Publisher, Maybe } from 'src/generated/graphql'
import { User } from '@concepts/Auth/types/User'

export type GTMEntities = {
  publisher: Partial<Publisher>
  affiliate?: Partial<Affiliate>
  user?: Partial<User>
  isSegmentFacebookEnabled: boolean
  segmentKey: string
}

export type WindowWithAppGlobalData = Window & {
  _queue?: Array<Function>
  _publisher?: {
    id: number
    hostname: string
    name: string
    vertical: string
    brand_primary_color: string
  }
  _user?: Maybe<{
    id: number
    email: string
  }>
  SC?: {
    config?: {
      user?: Function
      publisher?: Function
    }
  }
  SEGMENT_WRITE_KEY?: string
}

const shouldEnqueue = (): boolean => {
  return (window as WindowWithAppGlobalData)._publisher === undefined
}

const enqueue = (func: Function): void => {
  const windowWithAppGlobalData = window as WindowWithAppGlobalData

  windowWithAppGlobalData._queue = windowWithAppGlobalData._queue || []
  windowWithAppGlobalData._queue.push(func)
}

const timer = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms))

const processQueue = async (): Promise<void> => {
  const queue = (window as WindowWithAppGlobalData)._queue || []

  // When dispatching many actions at the same time, it uses the wrong event action when triggering all of them
  // so waiting makes it send the correct event action
  for (const cb of queue) {
    await timer(300)
    cb()
  }
}

const getDataLayerAttrs = ({
  publisher,
  affiliate,
  user,
  isSegmentFacebookEnabled,
  segmentKey
}: GTMEntities): DataLayerArgs => {
  // Returns the exact same attributes from stack-shops dataLayer
  // https://github.com/stacksocial/stack-shops/blob/develop/app/views/application/_tracking_js.html.erb#L11-L30

  const appData = {
    publisherId: `${publisher.databaseId}`,
    publisherName: publisher.name,
    publisherSmsLongCode: publisher.smsLongCode,
    vertical: publisher.vertical,
    affiliateId: affiliate?.databaseId
      ? `${(affiliate as Affiliate).databaseId}`
      : '',
    showEmailCapture: `${publisher.showEmailModal}`,
    isSegmentFacebookEnabled: `${isSegmentFacebookEnabled}`,
    segmentKey: `${segmentKey}`,
    appEnvironment: APP_ENV
  }

  const userData = user?.email
    ? {
        user_details: {
          email: user.email,
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          city: user.info?.city || '',
          country: user.info?.country || '',
          phoneNumber: pathOr('', [0, 'phoneNumber'], user.addresses),
          state: user.info?.state || '',
          zip: user.info?.zip || ''
        }
      }
    : {}

  return {
    ...appData,
    ...userData
  } as DataLayerArgs
}

const getConfig = ({
  publisher,
  affiliate,
  user,
  isSegmentFacebookEnabled,
  segmentKey
}: GTMEntities): TagManagerArgs => {
  const config: TagManagerArgs = {
    gtmId: GTM_CONTAINER_ID,
    dataLayer: getDataLayerAttrs({
      publisher,
      affiliate,
      user,
      isSegmentFacebookEnabled,
      segmentKey
    })
  }

  if (!isProduction()) {
    if (GTM_CONTAINER_AUTH) config.auth = GTM_CONTAINER_AUTH
    if (GTM_CONTAINER_ENV) config.preview = GTM_CONTAINER_ENV
  }

  return config
}

const dataLayer = (args: DataLayerArgs): void => {
  if (shouldEnqueue()) {
    enqueue(() => dataLayer(args))
    return
  }

  TagManager.dataLayer({
    ...args,
    dataLayer: args.dataLayer
  })
}

const SCDataLayer = (args: DataLayerArgs): void => {
  if (shouldEnqueue()) {
    enqueue(() => SCDataLayer(args))
    return
  }

  const baseArgs = {
    currentPublisher: (window as WindowWithAppGlobalData)._publisher || '',
    currentUser: (window as WindowWithAppGlobalData)._user || ''
  }

  TagManager.dataLayer({
    ...args,
    dataLayer: { ...baseArgs, ...args.dataLayer }
  })
}

// Override TagManager's default `initialize` function to monkey patch it.
// The reason is that TagManager only runs on client-side and we need the
// <noscript> GTM tag to be included on server-side.
// The override makes it not duplicate the <noscript> when TagManager runs on client-side.
//
// We're adding <GTMNoScript /> manually on _document.tsx
// TODO: We can drop react-gtm dependency in the future.
TagManager.initialize = function ({
  gtmId,
  events = {},
  dataLayer,
  dataLayerName = 'dataLayer',
  auth = '',
  preview = ''
}) {
  const gtm = (this as unknown as { gtm: Function }).gtm({
    id: gtmId,
    events,
    dataLayer: dataLayer || undefined,
    dataLayerName,
    auth,
    preview
  })

  if (dataLayer) document.head.appendChild(gtm.dataScript)
  document.head.insertBefore(gtm.script(), document.head.childNodes[0])
}

const initialize = (params: GTMEntities): void => {
  const { publisher, user } = params
  const windowWithAppGlobalData = window as WindowWithAppGlobalData

  windowWithAppGlobalData._queue = windowWithAppGlobalData._queue || []

  windowWithAppGlobalData._publisher = {
    id: publisher.databaseId as number,
    hostname: publisher.hostname as string,
    name: publisher.name as string,
    vertical: publisher.vertical as string,
    brand_primary_color: publisher?.layout?.primaryColor as string
  }

  windowWithAppGlobalData._user = user
    ? {
        id: user.id as number,
        email: user.email as string
      }
    : null

  // Support window.SC.config used on third-parties/legacy shops
  windowWithAppGlobalData.SC = windowWithAppGlobalData.SC || {}
  windowWithAppGlobalData.SC.config = windowWithAppGlobalData.SC.config || {}

  windowWithAppGlobalData.SC.config = mergeDeepRight(
    windowWithAppGlobalData.SC.config,
    {
      user: () => windowWithAppGlobalData._user,
      publisher: () => windowWithAppGlobalData._publisher
    }
  )

  TagManager.initialize(getConfig(params))
  processQueue()
}

export default {
  initialize,
  getConfig,
  SCDataLayer,
  dataLayer
}
