type FontFamilies = Record<string, string>
type LineHeights = Record<string, number>
type FontWeights = Record<string, number>

const fonts: FontFamilies = {
  heading: `sofia-pro,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      sans-serif
      `,
  body: `sofia-pro,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      sans-serif
      `
}

const lineHeights: LineHeights = {
  base: 1.4,
  heading: 1.2,
  button: 1
}

const fontWeights: FontWeights = {
  body: 400,
  heading: 700,
  bold: 700
}

const letterSpacings = {
  body: 'normal',
  caps: '.2em'
}

const fontSizes = {
  0: '0rem',
  1: '0.875rem',
  2: '1rem',
  3: '1.125rem',
  4: '1.3125rem',
  5: '1.625rem',
  6: '2rem',
  7: '2.125rem',
  8: '3rem'
}

const fontsStyles = {
  fonts,
  lineHeights,
  fontSizes,
  fontWeights,
  letterSpacings
}

export default fontsStyles
