import { Textarea as ChakraTextArea } from '@chakra-ui/react'
import { UIStyles } from '../types'
import { ChangeEventHandler } from 'react'

type Props = {
  sx?: UIStyles
  id?: string
  name?: string
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  defaultValue?: string
  value?: string
  children?: React.ReactNode
  required?: boolean
}

const Textarea = ({ children, ...props }: Props) => {
  return <ChakraTextArea {...props}>{children}</ChakraTextArea>
}

export default Textarea
