/* istanbul ignore file */
import { createContext, useContext } from 'react'
import { Auth } from '../types/Auth'
import { initialState } from './reducer'

export type AuthContextType = [Auth, Record<string, Function>]

export const AuthContext = createContext<AuthContextType>([initialState, {}])

export const useAuthContext = (): AuthContextType => useContext(AuthContext)
