export type FontFamilies = Record<string, string>
export type FontSizes = Array<string>
export type FontWeights = Record<string, number>
export type LineHeights = Record<string, number>
export type LetterSpacings = Record<string, string>

export type Fonts = {
  fonts: FontFamilies
  fontSizes: FontSizes
  fontWeights: FontWeights
  lineHeights: LineHeights
  letterSpacings: LetterSpacings
}

const fontFamilies: FontFamilies = {
  body: `"sofia-pro",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      sans-serif
      `
}
//                            0  1   2   3   4   5   6   7   8
const fontSizes: FontSizes = [0, 14, 16, 18, 21, 26, 32, 34, 48].map(
  (n) => `${n / 16}rem`
)

const fontWeights: FontWeights = {
  body: 400,
  heading: 700,
  bold: 700
}

const lineHeights: LineHeights = {
  body: 1.4,
  heading: 1.2,
  button: 1
}
const letterSpacings: LetterSpacings = {
  body: 'normal',
  caps: '.2em'
}

const fonts: Fonts = {
  fonts: fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings
}

export default fonts
