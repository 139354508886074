import { F } from 'ramda'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useNotyfContext } from '@atoms/Notyf/context'
import { Flash } from '../domain/Flash'
import {
  getFlash,
  resetFlash,
  storeFlash
} from '../infrastructure/flashStorage'

import { isClient } from '@utils/env'

type FlashContextValues = {
  info: (message: string) => void
  error: (message: string) => void
}

const FlashContext = createContext<FlashContextValues>({
  info: F,
  error: F
})

export const FlashProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const notyf = useNotyfContext()

  useEffect(() => {
    if (isClient()) {
      const flash = getFlash()

      if (!flash) {
        return
      }

      const { type, message, duration } = flash

      // istanbul ignore next
      notyf?.open({
        type,
        message,
        duration
      })

      resetFlash()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createFlash = useCallback((flash: Flash) => {
    storeFlash(flash)
  }, [])

  const info = useCallback(
    (message: string) =>
      createFlash({
        type: 'info',
        message
      }),
    [createFlash]
  )

  const error = useCallback(
    (message: string) =>
      createFlash({
        type: 'error',
        message
      }),
    [createFlash]
  )

  const value = useMemo(
    () => ({
      info,
      error
    }),
    [info, error]
  )

  return <FlashContext.Provider value={value}>{children}</FlashContext.Provider>
}

export const useFlashMessage = (): FlashContextValues =>
  useContext(FlashContext)
