import { AriaRole, MouseEventHandler } from 'react'
import { Flex as ChakraFlex } from '@chakra-ui/react'

import { AsType, UIStyles } from '../types'

type Props = {
  sx?: UIStyles
  as?: AsType
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
  role?: AriaRole
  id?: string
  children?: React.ReactNode
}

const Flex = ({ children, ...props }: Props) => {
  return <ChakraFlex {...props}>{children}</ChakraFlex>
}

export default Flex
