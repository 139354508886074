import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

type GuestToken = {
  gtoken?: string
}

export const parseGuestToken = (query: ParsedUrlQuery): GuestToken => {
  return { gtoken: query.gtoken as string | undefined }
}

const useGuestToken = (): GuestToken => {
  return parseGuestToken(useRouter().query)
}

export default useGuestToken
