import { removeCookie } from '@utils/cookies'
import Cookies, { CookieAttributes } from 'js-cookie'
import { is, any, values, dissoc } from 'ramda'

export type UserData = {
  id: number
  email: string
  first_name: string
  last_name: string
  isAuthenticated: boolean
  avatarUrl?: string | null
}

type Header = {
  token: { alg: string; typ: string }
  claim: { exp: number; sub: string; attributes: UserData }
  signature: string
}

export type Tokens = {
  accessToken?: string
  refreshToken?: string
  sessionToken?: string
  guestToken?: string
  uidocp?: string
}

const decode64 = (value: string): string => {
  try {
    if (typeof window !== 'undefined')
      return window.decodeURIComponent(window.escape(window.atob(value)))

    const buffer = Buffer.from(value, 'base64')
    return buffer.toString('utf-8')
  } catch (_err) {
    return 'null'
  }
}

const read = (token: string): Header => {
  const parts = token.split('.')

  return {
    token: JSON.parse(decode64(parts[0])),
    claim: JSON.parse(decode64(parts[1])),
    signature: parts[2] || ''
  }
}

const tokenToCookieMapping = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  sessionToken: 'session_token',
  guestToken: 'guest_token',
  uidocp: 'uidocp'
}

const setCookie = (
  name: keyof Tokens,
  value: string,
  options?: CookieAttributes
): void => {
  Cookies.set(tokenToCookieMapping[name], value, options)
}

const getCookie = (key: keyof Tokens): string | undefined =>
  Cookies.get(tokenToCookieMapping[key])

const setToken = (
  key: keyof Tokens,
  auth: Tokens,
  options?: CookieAttributes
): void => {
  const value = auth[key]

  if (is(String, value)) {
    setCookie(key, value as string, options)
  }
}

const getToken = (key: keyof Tokens, auth: Tokens): string | undefined => {
  const value = auth[key]

  if (is(String, value)) {
    return value
  } else {
    return getCookie(key)
  }
}

const removeToken = (key: keyof Tokens): void =>
  Cookies.remove(tokenToCookieMapping[key])

export const hasAuthTokens = (auth: Tokens = {} as Tokens): boolean => {
  const authTokens: Tokens = dissoc('guestToken', auth)
  return any(is(String))(values(authTokens))
}

export const isTrueGuestCheckout = (
  { uidocp }: Tokens = {} as Tokens
): boolean => {
  return is(String, uidocp)
}

export const hasGuestToken = (
  { guestToken }: Tokens = {} as Tokens
): boolean => {
  return is(String, guestToken)
}

const withBearer = (token?: string, withPrefix?: boolean) => {
  if (token) {
    return withPrefix ? `Bearer ${token}` : token
  }
}

export const getAuthTokens = (
  auth: Tokens = {} as Tokens,
  withPrefix = true
): Tokens => {
  const accessToken = getToken('accessToken', auth)
  const refreshToken = getToken('refreshToken', auth)
  const sessionToken = getToken('sessionToken', auth)
  const guestToken = getToken('guestToken', auth)

  return {
    accessToken: withBearer(accessToken, withPrefix),
    refreshToken: withBearer(refreshToken, withPrefix),
    sessionToken,
    guestToken,
    uidocp: auth.uidocp
  }
}

export const updateTokens = (tokens: Tokens): void => {
  setToken('accessToken', tokens)
  setToken('refreshToken', tokens, { expires: 7 })
  setToken('sessionToken', tokens)
}

export const clearTokens = (): void => {
  removeToken('accessToken')
  removeToken('refreshToken')
  removeToken('sessionToken')
  removeCookie('cart_id')
  removeCookie('encrypted_cart_id')
}

// TODO: We REALLY should consider changing this behavior.
//       Just checking if the cookies are set is definitely not safe.
export const userIsAuthenticated = (cookieString?: string): boolean => {
  if (cookieString) {
    return (
      cookieString.includes(tokenToCookieMapping.accessToken) &&
      cookieString.includes(tokenToCookieMapping.refreshToken) &&
      cookieString.includes(tokenToCookieMapping.sessionToken)
    )
  }

  return hasAuthTokens({
    accessToken: getCookie('accessToken'),
    refreshToken: getCookie('refreshToken'),
    sessionToken: getCookie('sessionToken')
  })
}

export const userData = (accessTokenCookie?: string): Partial<UserData> => {
  const token = accessTokenCookie || getCookie('accessToken')
  if (!token) return {}

  const { claim } = read(token)
  if (!claim?.attributes) return {}

  return {
    ...claim.attributes,
    id: +claim.sub.replace('User/', ''),
    isAuthenticated: true
  }
}
