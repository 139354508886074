import { Image as ChakraImage } from '@chakra-ui/react'
import { UIStyles } from '../types'
import { ReactEventHandler } from 'react'

export type ImageProps = {
  sx?: UIStyles
  src: string
  alt: string
  title?: string
  className?: string
  onError?: ReactEventHandler<HTMLImageElement>
  loading?: 'eager' | 'lazy'
  id?: string
}

const Image = ({ ...props }: ImageProps) => {
  return <ChakraImage {...props} />
}

export default Image
