import gql from 'graphql-tag'
import { FetchPolicy } from 'apollo-client'

import { TApolloClient } from 'src/types/apollo'
import { User } from '../types/User'

import UserDTO from '@concepts/Auth/dto/UserDTO'

export const VIEWER_FRAGMENT = gql`
  fragment ViewerFragment on User {
    databaseId
    email
    firstName
    lastName
    avatarUrl
    flagged
    creditsAvailableCents
    favoriteSaleIds
    referrerId
    isGuest
    canClaimAccount
    hasActiveRecurringSale

    info {
      city
      country
      state
      dateOfBirth
      gender
      zip
      fastCheckout
      phoneNumber
    }

    addresses(last: 1) {
      nodes {
        countryCode
        city
        state
        zip
        phoneNumber
      }
    }

    orders(last: 1) {
      nodes {
        databaseId
      }
    }

    recurringSales(last: 1) {
      nodes {
        databaseId
      }
    }
  }
`

export const VIEWER_QUERY = gql`
  query Viewer {
    viewer {
      ...ViewerFragment
    }
  }

  ${VIEWER_FRAGMENT}
`

const find = async (
  apolloClient: TApolloClient,
  fetchPolicy: FetchPolicy = 'network-only'
): Promise<User> => {
  const { data } = await apolloClient.query({
    query: VIEWER_QUERY,
    fetchPolicy
  })

  const user = data?.viewer

  return UserDTO.parse(user)
}

export default { find }
