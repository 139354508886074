import Head from 'next/head'
import Script from 'next/script'
import type { Publisher } from 'src/generated/graphql'

type Props = {
  publisher: Publisher
}

const Digioh: React.FC<React.PropsWithChildren<Props>> = ({ publisher }) => {
  if (!publisher.showEmailModal) return null

  return (
    <>
      <Script
        strategy="lazyOnload"
        src="https://www.lightboxcdn.com/vendor/2b159396-5db4-4f36-950e-cd35347c27bb/lightbox_speed.js"
      />

      <Head>
        {/*
      Used by Digioh to get publisher vertical, name and dispatch analytics events
      TODO: We must remove it once the full legacy shop was dropped and Digioh events were migrated
      */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.SC = window.SC || {
              config: {
                publisher: function () {
                  return {
                    vertical: '${publisher.vertical}',
                    name: '${publisher.name}'
                  }
                }
              },
              code: {
                analytics: {
                  analyticsEvent: function(category, action, opt_label) {
                    dataLayer.push({
                      'event': 'analyticsEvent',
                      'eventCategory': category,
                      'eventAction': action,
                      'eventLabel': opt_label
                    })
                  }
                }
              }
            }
          `
          }}
        />
      </Head>
    </>
  )
}

export default Digioh
