import { createContext, useContext } from 'react'
import type { Feature } from 'src/generated/graphql'
import { F } from 'ramda'

export type FeatureFlagContextType = {
  features: Array<Feature>
  isEnabled: Function
  isDisabled: Function
}

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  features: [],
  isEnabled: F,
  isDisabled: F
})

export const useFeatureFlag = (): FeatureFlagContextType =>
  useContext(FeatureFlagContext)
