import {
  ClipboardEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  LegacyRef,
  Ref,
  forwardRef
} from 'react'
import { Input as ChakraInput } from '@chakra-ui/react'

import { UIStyles } from '../types'

export type InputProps = {
  children?: React.ReactNode
  sx?: UIStyles
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  value?: string | number
  ref?: Ref<HTMLInputElement>
  id?: string
  placeholder?: string
  focusBorderColor?: string
  name?: string
  defaultValue?: string
  required?: boolean
  autoComplete?: string
  type?: HTMLInputTypeAttribute
  maxLength?: number
  disabled?: boolean
  onPaste?: ClipboardEventHandler<HTMLInputElement>
}

const Input = forwardRef(({ children, sx, ...props }: InputProps, ref) => {
  return (
    <ChakraInput ref={ref as LegacyRef<HTMLInputElement>} {...props} sx={sx}>
      {children}
    </ChakraInput>
  )
})

export default Input
