import { createContext, useContext } from 'react'
import { BaseProps } from 'src/types/page-props'

export const GlobalContext = createContext<BaseProps>({
  cookies: {},
  apolloLink: undefined,
  page: ''
})

export const useGlobalContext = (): BaseProps => useContext(GlobalContext)
