import { MouseEventHandler } from 'react'

import { Card as ChakraCard } from '@chakra-ui/react'
import { AsType, UIStyles } from '../types'

export type CardProps = {
  variant?: 'elevated' | 'outline' | 'filled' | 'unstyled'
  sx?: UIStyles
  as?: AsType
  onClick?: MouseEventHandler
  children?: React.ReactNode
}

const Card = ({
  variant = 'outline',
  sx = {},
  children,
  ...props
}: CardProps) => (
  <ChakraCard variant={variant} {...props} sx={sx}>
    {children}
  </ChakraCard>
)

export default Card
