import { getContrast } from 'polished'

const WCAG_TEXT_CONTRAST_RATIO = 4.5

const checkFontContrast = (backgroundColor: string, desiredColor: string) =>
  getContrast(backgroundColor, desiredColor) > WCAG_TEXT_CONTRAST_RATIO

type DetermineFontColor = {
  backgroundColor: string
  desiredColor: string
  fallbackColor: string
}

export const determineFontColor = ({
  backgroundColor,
  desiredColor,
  fallbackColor
}: DetermineFontColor): string => {
  return checkFontContrast(backgroundColor, desiredColor)
    ? desiredColor
    : fallbackColor
}
