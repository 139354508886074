export type Cards = Record<string, object>

const cards: Cards = {
  default: {
    borderRadius: 'default',
    border: '1px solid',
    borderColor: 'grays.2'
  },
  primary: {
    variant: 'cards.default',
    borderColor: 'primary'
  }
}

export default cards
