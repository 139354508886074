const buttons = {
  default: {
    bg: 'white',
    color: 'black',
    border: '1px solid',
    borderColor: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily: 'body',
    lineHeight: 'button',
    borderRadius: 'circle',
    verticalAlign: 'middle',
    textAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    display: 'inline-flex',
    '&:hover': { color: 'primary', borderColor: 'white' },
    '&:active': { color: 'primaryHover', borderColor: 'white' },
    '&:focus': {
      outline: 'none'
    }
  },
  primary: {
    variant: 'buttons.default',
    color: 'white',
    bg: 'primary',
    borderColor: 'primary',
    '&:hover': {
      bg: 'primaryHover',
      borderColor: 'primaryHover'
    },
    '&:active': {
      bg: 'primaryActive',
      borderColor: 'primaryActive'
    }
  },
  dark: {
    variant: 'buttons.default',
    color: 'white',
    bg: 'blacks.0',
    borderColor: 'blacks.0',
    '&:hover': {
      bg: 'blacks.1'
    },
    '&:active': {
      bg: 'blacks.1',
      borderColor: 'blacks.1'
    }
  },
  outline: {
    variant: 'buttons.default',
    color: 'black',
    bg: 'transparent',
    borderColor: 'primary',
    '&:hover': {
      color: 'primaryHover',
      borderColor: 'primaryHover'
    },
    '&:active': {
      color: 'primaryActive',
      borderColor: 'primaryActive'
    }
  },
  outlineDark: {
    variant: 'buttons.default',
    color: 'black',
    bg: 'transparent',
    borderColor: 'blacks.0',
    '&:hover': {
      color: 'white',
      bg: 'blacks.0',
      borderColor: 'blacks.0'
    },
    '&:active': {
      color: 'white',
      bg: 'blacks.1',
      borderColor: 'blacks.1'
    }
  },
  tertiary: {
    variant: 'buttons.default',
    color: 'black',
    bg: 'white',
    fontWeight: 'normal',
    borderColor: 'grays.3',
    '&:hover': {
      color: 'primaryHover',
      borderColor: 'primaryHover'
    },
    '&:active': {
      color: 'primaryActive',
      borderColor: 'primaryActive'
    }
  },
  disabled: {
    variant: 'buttons.default',
    opacity: '.3',
    color: 'white',
    bg: 'blacks.0',
    borderColor: 'blacks.0',
    '&:hover': {
      bg: 'blacks.0'
    },
    '&:active': {
      bg: 'blacks.0',
      borderColor: 'blacks.0'
    }
  },
  link: {
    variant: 'buttons.default',
    color: 'black',
    bg: 'transparent',
    fontWeight: 'body',
    textTransform: 'none',
    letterSpacing: 'none',
    textDecoration: 'underline',
    padding: 0,
    border: 0,
    '&:hover': {
      color: 'black'
    },
    '&:active': {
      color: 'black'
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none'
    }
  },
  capsLink: {
    variant: 'buttons.default',
    textTransform: 'uppercase',
    letterSpacing: '0.225em',
    color: 'black',
    bg: 'transparent',
    padding: 0,
    border: 0,
    '&:hover': {
      color: 'primaryHover'
    },
    '&:active': {
      color: 'primaryActive'
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none'
    }
  },
  icon: {
    variant: 'buttons.default'
  },
  close: {
    variant: 'buttons.default',
    border: 0,
    letterSpacing: 'body',
    bg: 'transparent',
    '&:hover': {
      bg: 'transparent'
    },
    '&:active': {
      bg: 'transparent'
    }
  },
  square: {
    variant: 'buttons.primary',
    borderRadius: 'default'
  },
  purchases: {
    variant: 'buttons.default',
    borderRadius: 'default',
    justifyContent: 'center',
    color: 'black',
    bg: 'white',
    fontWeight: 'normal',
    borderColor: 'grays.3',
    '&:hover': {
      color: 'primaryHover',
      borderColor: 'primaryHover'
    },
    '&:active': {
      color: 'primaryActive',
      borderColor: 'primaryActive'
    }
  }
} as const

export type Buttons = typeof buttons

export default buttons
