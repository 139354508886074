import { getCookie, removeCookie, setCookie } from '@utils/cookies'
import { Flash } from '../domain/Flash'

export const parseFlash = (flash: string): Flash => {
  const parseFlash = JSON.parse(flash)

  return {
    ...parseFlash,
    message: decodeURI(parseFlash.message)
  }
}

export const getFlash = (cookie?: string): Flash | undefined => {
  const flash = getCookie('flash', cookie)

  if (flash) {
    return parseFlash(flash)
  }
}

export const storeFlash = (flash: Flash): void => {
  setCookie('flash', JSON.stringify(flash))
}

export const resetFlash = (): void => removeCookie('flash')
