import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr } from 'ramda'
import {
  PromoteGuestUserInput,
  MutationError,
  TokenPair
} from 'src/generated/graphql'

const PROMOTE_GUEST_USER_MUTATION = gql`
  mutation PromoteGuestUser($input: PromoteGuestUserInput!) {
    promoteGuestUser(input: $input) {
      tokenPair {
        accessToken
        refreshToken
      }
      sessionToken
      errors {
        message
        path
      }
    }
  }
`

export type PromoteGuestResult = {
  accessToken?: string
  refreshToken?: string
  sessionToken?: string
  errors?: Array<MutationError>
}

type MutationResult = {
  tokenPair?: TokenPair
  sessionToken?: string
  errors: Array<MutationError>
}

const promote = async (
  params: PromoteGuestUserInput,
  apolloClient: TApolloClient
): Promise<PromoteGuestResult> =>
  apolloClient
    .mutate({
      mutation: PROMOTE_GUEST_USER_MUTATION,
      variables: { input: params }
    })
    .then(pathOr({} as MutationResult, ['data', 'promoteGuestUser']))
    .then(
      (response: MutationResult): PromoteGuestResult => ({
        errors: response.errors,
        accessToken: response.tokenPair?.accessToken,
        refreshToken: response.tokenPair?.refreshToken,
        sessionToken: response.sessionToken
      })
    )

export default { promote }
