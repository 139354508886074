import analytics from '@lib/gtm/analytics'
import { Sale, Category, Maybe } from 'src/generated/graphql'
import { pathOr, pipe, join, map } from 'ramda'
import {
  GA4CartProducts,
  GACartProducts
} from '@concepts/Checkout/types/Checkout'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import { getItemListName, setItemListNamesCookie } from './utils'

export type AnalyticsSale = {
  databaseId?: Maybe<number>
  name?: string
  title?: string
  priceInCents: number
  slug?: string
}

type ImpressionSaleGA4 = {
  item_category: string
  item_id: string
  item_name: string
  price: number
  item_position: string
  list?: string
  item_list_name?: string
}

type GA4Product = {
  item_id?: string
  item_name?: string
  item_category?: string
  price: number
  item_list_name?: string
}

type GA4Ecommerce = {
  impressions?: Array<ImpressionSaleGA4>
  item_list_id?: number
  items?: Array<GA4Product>
}

type GA4EventAttrs = {
  ecommerce?: GA4Ecommerce
  categorySales?: Array<Sale>
  previouslyPurchasedProducts?: boolean
}

const track = (action: string, attrs?: GA4EventAttrs): void => {
  analytics.dataLayer({ ecommerce: null })
  analytics.trackSCDataLayer(action, attrs)
}

type EcommerceAnalytics = {
  trackImpression: (items: Array<AnalyticsSale>, module: string) => void
  trackProductDetails: (items: SaleDetailsType, module: string) => void
  trackProductClick: (
    item: AnalyticsSale,
    module: string,
    position: number
  ) => void
  trackUserVisit: (previousPurchases: boolean) => void
  trackV2HomePageViewed: () => void
  trackV2CategoryViewed: (sales: Array<Sale>) => void
  trackCheckout: (products: Array<GACartProducts>) => void
  trackAddToCart: (products: Array<GACartProducts>) => void
  trackRemoveFromCart: (products: Array<GACartProducts>) => void
}

const toCategoryName = (item: Category) => item.name

const parseProductToGA4 = (
  item: AnalyticsSale,
  module: string,
  position: number
): ImpressionSaleGA4 => ({
  item_category: pipe(
    pathOr([], ['categories']),
    map(toCategoryName),
    join('/')
  )(item) as string,
  item_id: String(item.databaseId),
  item_name: item.name || item.title || '',
  price: (item.priceInCents || 0) / 100,
  item_position: String(position),
  item_list_name: module
})

const parseProductItemsGA4 = (
  items: Array<AnalyticsSale>,
  module: string
): Array<ImpressionSaleGA4> => {
  return items.map((item, index: number) =>
    parseProductToGA4(item, module, index + 1)
  )
}

const productsToGA4 = (
  products: Array<GACartProducts>
): Array<GA4CartProducts> => {
  return products.map((product) => {
    const itemListName = getItemListName(product.slug)

    return {
      item_category: product.category,
      item_id: product.id,
      item_name: product.name,
      price: product.price,
      quantity: product.quantity,
      item_list_name: itemListName
    }
  })
}

const ecommerceAnalytics: EcommerceAnalytics = {
  trackImpression: (items, module) => {
    track('productImpressionGA4', {
      ecommerce: {
        items: parseProductItemsGA4(items, module)
      }
    })
  },
  trackProductDetails: (item, module) => {
    track('productDetailGA4', {
      ecommerce: {
        items: [
          {
            item_id: String(item.id),
            item_name: item.name,
            item_category: item.category.name,
            price: (item.priceInCents || 0) / 100,
            item_list_name: module
          }
        ]
      }
    })
  },
  trackProductClick: (product, module, position) => {
    setItemListNamesCookie({
      itemListName: module,
      slug: product.slug
    })

    track('productClickGA4', {
      ecommerce: {
        item_list_id: position,
        items: [
          {
            item_id: String(product.databaseId),
            item_name: product.name,
            price: (product.priceInCents || 0) / 100,
            item_list_name: module
          }
        ]
      }
    })
  },
  trackUserVisit: (hasPreviousPurchase) => {
    track('USER_VISITS', { previouslyPurchasedProducts: hasPreviousPurchase })
  },
  trackV2HomePageViewed: () => {
    track('V2_HOME_PAGE_VIEWED')
  },
  trackV2CategoryViewed: (sales) => {
    track('V2_CATEGORY_VIEWED', {
      categorySales: sales
    })
  },
  trackCheckout: (products) => {
    track('onCheckoutGA4', {
      ecommerce: {
        items: productsToGA4(products)
      }
    })
  },
  trackAddToCart: (products) => {
    track('addToCartGA4', {
      ecommerce: {
        items: productsToGA4(products)
      }
    })
  },
  trackRemoveFromCart: (products) => {
    track('removeFromCartGA4', {
      ecommerce: {
        items: productsToGA4(products)
      }
    })
  }
}

export default ecommerceAnalytics
