import {
  AriaRole,
  FormEventHandler,
  MouseEventHandler,
  KeyboardEventHandler,
  RefObject
} from 'react'

import { Box as ChakraBox, forwardRef } from '@chakra-ui/react'
import { AsType, UIStyles } from '../types'

export type BoxProps = {
  sx?: UIStyles
  as?: AsType
  'aria-label'?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  onSubmit?: FormEventHandler<HTMLDivElement>
  className?: string
  tabIndex?: number
  onChange?: FormEventHandler<HTMLDivElement>
  role?: AriaRole
  name?: string
  ref?: RefObject<HTMLElement>
  onKeyUp?: KeyboardEventHandler<HTMLDivElement>
  id?: string
  children?: React.ReactNode
}

const Box = forwardRef(({ children, ...props }: BoxProps, ref) => {
  return (
    <ChakraBox ref={ref} {...props}>
      {children}
    </ChakraBox>
  )
})

export default Box
