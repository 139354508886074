import analytics from './analytics'

type EventAttrs = {
  eventLabel?: string
  eventValue?: string | number
}

type CheckoutAnalytics = {
  emailLogin: Function
  clickedCreateAnAccount: Function
  clickedLogin: Function
  enteredPassword: Function
  facebookLogin: Function
  facebookSignUp: Function
  googleLogin: Function
  googleSignUp: Function
  appliedCoupon: Function
  continueToPayment: Function
  continueToShipping: Function
  clickedAddCreditCard: Function
  clickedAddAddress: Function
  removeCartItem: Function
  addUpsell: Function
  clickedMoreUpsellSaleDetails: Function
  upsellImpression: Function
  clickedCompleteOrder: Function
  clickedPayPal: Function
  displayed3DVerification: Function
  succeeded3DVerification: Function
  failed3DVerification: Function
  fastCheckoutLogin: Function
  clickedOpenFastCheckout: Function
  clickedCloseFastCheckout: Function
  clickedResendCode: Function
  fluentImpression: Function
  checkoutStepEmail: Function
  checkoutStepAddress: Function
  checkoutStepPayment: Function
  checkoutCompleteOrder: Function
  pageViewed: Function
}

const DEFAULT_CATEGORY = 'Checkout'

const track = (action: string, attrs: EventAttrs = {}): void => {
  analytics.track(DEFAULT_CATEGORY, action, attrs)
}

const checkoutAnalytics: CheckoutAnalytics = {
  emailLogin: (): void => {
    track('Email Login')
  },
  clickedCreateAnAccount: (): void => {
    track('Clicked Create An Account')
  },
  clickedLogin: (): void => {
    track('Clicked Login')
  },
  enteredPassword: (): void => {
    track('Entered Password')
  },
  facebookLogin: (): void => {
    track('Facebook Login')
  },
  facebookSignUp: (): void => {
    track('Facebook Signup')
  },
  googleLogin: (): void => {
    track('Google Login')
  },
  googleSignUp: (): void => {
    track('Google Signup')
  },
  appliedCoupon: (couponCode: string): void => {
    track('Applied Coupon', {
      eventLabel: couponCode
    })
  },
  continueToPayment: (): void => {
    track('Continued To Payment')
  },
  continueToShipping: (): void => {
    track('Continue to Shipping')
  },
  clickedAddCreditCard: (): void => {
    track('Clicked Add New Credit Card')
  },
  clickedAddAddress: (): void => {
    track('Clicked Add New Address')
  },
  removeCartItem: (saleId: number): void => {
    track('Removed Cart Item From Checkout', {
      eventLabel: `${saleId}`
    })
  },
  addUpsell: (saleId: number): void => {
    track(`Added Checkout Upsell`, {
      eventLabel: `${saleId}`
    })
  },
  clickedMoreUpsellSaleDetails: (saleId: number) => {
    track(`Clicked Checkout Upsell More Details`, {
      eventLabel: `${saleId}`
    })
  },
  upsellImpression: (saleId: number): void => {
    track(`Checkout Upsell Impression`, {
      eventLabel: `${saleId}`
    })
  },
  clickedCompleteOrder: (label: string, value: number): void => {
    track('Clicked Complete Order', {
      eventLabel: label,
      eventValue: value
    })
  },
  clickedPayPal: (): void => {
    track('Clicked PayPal Payment Method')
  },
  displayed3DVerification: (): void => {
    track('Displayed 3D Verification')
  },
  succeeded3DVerification: (): void => {
    track('Succeeded 3D Verification')
  },
  failed3DVerification: (): void => {
    track('Failed 3D Verification')
  },
  fastCheckoutLogin: (): void => {
    track('Fast checkout Login')
  },
  clickedOpenFastCheckout: (): void => {
    track('Clicked open fast checkout')
  },
  clickedCloseFastCheckout: (): void => {
    track('Clicked close fast checkout')
  },
  clickedResendCode: (): void => {
    track('Clicked resend fast checkout code')
  },
  fluentImpression: (): void => {
    track('Fluent Impression')
  },
  checkoutStepEmail: (): void => {
    track('Checkout - Step 1 - Email')
  },
  checkoutStepAddress: (): void => {
    track('Checkout - Step 2 - Address')
  },
  checkoutStepPayment: (): void => {
    track('Checkout - Step 3 - Payment')
  },
  checkoutCompleteOrder: (): void => {
    track('Checkout - Complete Order')
  },
  pageViewed: (): void => {
    track('Checkout - Page Viewed')
  }
}

export default checkoutAnalytics
