import { SET_AUTH } from './actionTypes'
import { Auth } from '../types/Auth'
import { isPresent } from '@utils/logic'

const initialState = {
  user: null,
  isSignedIn: false,
  isGuest: true,
  loading: true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: Auth, action: any): Auth => {
  switch (action.type) {
    case SET_AUTH:
      return {
        user: action.user ? action.user : null,
        isSignedIn: isPresent(action.user),
        isGuest: Boolean(action.user?.isGuest),
        loading: false
      }
    default:
      return state
  }
}

export { reducer, initialState }
