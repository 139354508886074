import { clearTokens } from '@config/jwt'
import routes from '@config/routes'

import { isClient } from '@utils/env'
import Redirect from '@utils/redirect'
import { getSearchParam } from '@utils/url'

export const isLoginRequest = (url = ''): boolean => /users\/auth/.test(url)

export const redirectToLoginPage = (url?: string): void => {
  /* istanbul ignore next */
  if (isClient()) {
    let returnTo = window.location.pathname

    if (url) {
      const returnToFromUrl = getSearchParam(url, 'return_to')

      returnTo = returnToFromUrl || url
    }

    Redirect({
      pathname: routes.login(),
      external: true,
      query: {
        return_to: returnTo
      }
    })
  }
}

export const destroyTokensAndRedirect = (url?: string): void => {
  clearTokens()
  redirectToLoginPage(url)
}
