import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { dissoc } from 'ramda'

import { BaseProps, GlobalProps } from '../types/page-props'
import { User } from '@concepts/Auth/types/User'

import { GlobalContext } from '@config/contexts'

import { ApolloProvider } from '@apollo/react-hooks'
import { TApolloClient } from '../types/apollo'

import AuthProvider from '@concepts/Auth/store/Provider'
import { PublisherContext } from '@concepts/Publisher/store/context'
import FeatureFlagProvider from '@concepts/FeatureFlag/store/Provider'

import { Feature, Publisher } from '../generated/graphql'
import { RECAPTCHA_V3_SITE_KEY } from '@config/env'
import ReCaptchaProvider from '@concepts/ReCaptcha/store/ProviderV3'
import ReCaptchaV2Provider from '@concepts/ReCaptcha/store/ProviderV2'

import { FlashProvider } from '@concepts/Flash/store/FlashProvider'
import { WindowDimensionsProvider } from './windowDimensions/Provider'
import { useEffect } from 'react'

export type ApplicationProps = GlobalProps<{
  hostname: string
  fetchSSRUser: boolean
  features: Feature[]
  publisher: Publisher
  initialUser?: User
}>

type AppProviderProps = {
  pageProps: ApplicationProps
  apolloClient: TApolloClient
  publisher: Publisher
}

const AppProviders: React.FC<React.PropsWithChildren<AppProviderProps>> = ({
  pageProps,
  apolloClient,
  publisher,
  children
}) => {
  const { initialUser, features, fetchSSRUser } = pageProps
  const shouldFetchInitialUser = !fetchSSRUser && !initialUser

  useEffect(() => {
    const addTitleToIFrames = () => {
      const iframes = document.querySelectorAll('iframe')
      if (iframes) {
        Array.from(iframes).map((iframe, index) => {
          if (iframe.title === '') {
            iframe.title = `empty iframe ${index + 1}`
          }
          return iframe
        })
      }
    }

    window.addEventListener('load', addTitleToIFrames)

    return () => {
      window.removeEventListener('load', addTitleToIFrames)
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={dissoc('initialUser', { ...(pageProps as unknown as BaseProps) })}
    >
      <ApolloProvider client={apolloClient}>
        <WindowDimensionsProvider>
          <FlashProvider>
            <PublisherContext.Provider value={publisher}>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
                <ReCaptchaProvider>
                  <ReCaptchaV2Provider>
                    <AuthProvider
                      initialUser={initialUser}
                      shouldFetchUser={shouldFetchInitialUser}
                    >
                      <FeatureFlagProvider features={features}>
                        {children}
                      </FeatureFlagProvider>
                    </AuthProvider>
                  </ReCaptchaV2Provider>
                </ReCaptchaProvider>
              </GoogleReCaptchaProvider>
            </PublisherContext.Provider>
          </FlashProvider>
        </WindowDimensionsProvider>
      </ApolloProvider>
    </GlobalContext.Provider>
  )
}

export default AppProviders
