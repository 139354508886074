import { Feature, Publisher } from 'src/generated/graphql'
import { FeatureFlagContext } from './context'
import { usePublisherContext } from '../../Publisher/store/context'
import { useAuthContext } from '../../Auth/store/context'
import { getActor } from '../utils/buildFeatures'
import { useMemo, useCallback } from 'react'

type Props = {
  features: Array<Feature>
}

const FeatureFlagProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  features
}) => {
  const publisher = usePublisherContext()
  const [{ user }] = useAuthContext()

  const isSameActor = useCallback(
    (actor: string | null, featureActor?: string | null): boolean =>
      featureActor ===
      getActor({
        actor,
        publisher: publisher as Publisher,
        user
      }),
    [publisher, user]
  )

  const isEnabled = useCallback(
    (key: string, actor = null): boolean => {
      const feature = features.find(
        (feature) => isSameActor(actor, feature.actor) && feature.name === key
      )

      return Boolean(feature?.enabled)
    },
    [features, isSameActor]
  )

  const isDisabled = useCallback(
    (key: string, actor = null): boolean => !isEnabled(key, actor),
    [isEnabled]
  )

  const value = useMemo(
    () => ({ features, isEnabled, isDisabled }),
    [features, isEnabled, isDisabled]
  )

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export default FeatureFlagProvider
