import Cookies from 'js-cookie'
import { FAVORITE_SALE_COOKIE_NAME } from '@config/env'

type Props = {
  favoriteSaleId: { add_to_favorite_list?: string }
  removeFavoriteSaleCookie: () => void
}

const useFavoritedSale = (): Props => {
  const saleId = Cookies.get(FAVORITE_SALE_COOKIE_NAME)

  const favoriteSaleId = saleId
    ? {
        add_to_favorite_list: saleId
      }
    : {}

  const removeFavoriteSaleCookie = (): void => {
    Cookies.remove(FAVORITE_SALE_COOKIE_NAME)
  }

  return {
    favoriteSaleId,
    removeFavoriteSaleCookie
  }
}

export default useFavoritedSale
